import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from "./apiConfig.js";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import OrderMessage from '../OrderMessage'; // Import OrderMessage component

const ManagerOrders = () => {
    const [orders, setOrders] = useState([]);
    const [searchParams, setSearchParams] = useState({
        order_id: '',
        recipient_name: '',
        address: '',
        mobile: '',
        email: '',
        username: ''
    });
    const [activeTab, setActiveTab] = useState('待付款');
    const [orderCounts, setOrderCounts] = useState({
        待處理: 0,
        待付款: 0,
        待出貨: 0,
        待收貨: 0,
        訂單已完成: 0,
        待換貨: 0
    });
    const [sortOrder, setSortOrder] = useState('desc');
    const [showModal, setShowModal] = useState(false);
    const [deleteOrderId, setDeleteOrderId] = useState(null);
    const [expandedOrderId, setExpandedOrderId] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [picture, setPicture] = useState(null);
    const [discountData, setDiscountData] = useState({});
    const [shippingCostData, setShippingCostData] = useState([]);
    const [extraCharges, setExtraCharges] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [editOrderData, setEditOrderData] = useState(null);

    useEffect(() => {
        fetchOrders(searchParams, activeTab);
        fetchShippingCostData();
    }, [activeTab, sortOrder]);

    const fetchOrders = async (params, status) => {
        try {
            const response = await axios.get(`${baseURL}/cart/byadmin/`, { params: { ...params } });
            const filteredOrders = response.data.filter(order => order.status === status);
            setOrders(Array.isArray(filteredOrders) ? filteredOrders : []);
            updateOrderCounts(response.data);
            fetchDiscountData(filteredOrders);
            await fetchAndSetExtraCharges(filteredOrders); // 循環每個訂單並獲取額外收費
        } catch (error) {
            console.error("There was an error fetching the orders!", error);
            setOrders([]);
        }
    };

    const fetchDiscountData = (orders) => {
        orders.forEach(order => {
            axios.post(`${baseURL}/discount/getDiscountByOrder`, { order_id: order.order_id })
                .then(response => {
                    if (response.data.status === 1) {
                        setDiscountData(prevData => ({
                            ...prevData,
                            [order.order_id]: response.data.discounts
                        }));
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error("There was an error fetching the discount data!", error);
                });
        });
    };

    const fetchShippingCostData = async () => {
        try {
            const response = await axios.get(`${baseURL}/shippingCostSettings`);
            setShippingCostData(response.data);
        } catch (error) {
            console.error("Error fetching shipping cost settings:", error);
        }
    };

    const fetchExtraCharges = async () => {
        try {
            const response = await axios.get(`${baseURL}/order_extraCharges`);
            console.log("Data",response.data);
            const charges = Array.isArray(response.data) ? response.data : [];
            const chargesMap = charges.reduce((acc, charge) => {
                if (!acc[charge.order_id]) {
                    acc[charge.order_id] = [];
                }
                acc[charge.order_id].push(charge);
                return acc;
            }, {});
            setExtraCharges(chargesMap);
        } catch (error) {
            console.error("There was an error fetching extra charges!", error);
        }
    };

    const calculateExtraChargeTotal = (orderId) => {
        const chargesForOrder = extraCharges[orderId] || [];
        return chargesForOrder.reduce((total, charge) => total + parseFloat(charge.extraCharge || 0), 0);
    };
     

    const fetchAndSetExtraCharges = async (orders) => {
        for (const order of orders) {
            await fetchExtraCharges(order.order_id);
        }
    };
    
    
    const updateOrderCounts = (orders) => {
        const counts = {
            待處理: 0,
            待付款: 0,
            待出貨: 0,
            待收貨: 0,
            訂單已完成: 0,
            待換貨: 0
        };
        const seenOrderIds = new Set();
        orders.forEach(order => {
            if (!seenOrderIds.has(order.order_id) && counts[order.status] !== undefined) {
                counts[order.status]++;
                seenOrderIds.add(order.order_id);
            }
        });
        setOrderCounts(counts);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams({
            ...searchParams,
            [name]: value
        });
    };

    const handleSearch = () => {
        fetchOrders(searchParams, activeTab);
    };

    const toggleSortOrder = () => {
        setSortOrder(prevSortOrder => (prevSortOrder === 'desc' ? 'asc' : 'desc'));
    };

    const calculateTotal = (orders) => {
        return orders.reduce((total, order) => total + parseFloat(order.小計 || 0), 0);
    };

    const calculateOrderTotal = (orderItems) => {
        return orderItems.reduce((total, item) => total + parseFloat(item.小計 || 0), 0);
    };

    const calculateShippingCost = (totalAfterDiscount, address) => {
        let shippingCost = 0;
        let outlyingShippingCost = 0;

        for (const setting of shippingCostData) {
            if (totalAfterDiscount >= setting.shipping_cost_start && totalAfterDiscount <= setting.shipping_cost_end) {
                shippingCost = parseFloat(setting.shipping_cost);
                break;
            }
        }

        if (address.includes("金門縣") || address.includes("連江縣") || address.includes("澎湖縣")) {
            const outlyingShipping = shippingCostData.find(item => item.shipping_name === "離島運費");
            if (outlyingShipping) {
                outlyingShippingCost = parseFloat(outlyingShipping.shipping_cost);
            }
        }

        return { shippingCost, outlyingShippingCost };
    };

    const groupOrdersByOrderId = (orders) => {
        return orders.reduce((groups, order) => {
            const group = groups[order.order_id] || [];
            group.push(order);
            groups[order.order_id] = group;
            return groups;
        }, {});
    };

    const groupedOrders = groupOrdersByOrderId(orders);

    const tabs = ['待付款', '待處理', '待出貨', '待收貨', '訂單已完成', '待換貨'];

    const handleStatusChange = async (orderId, newStatus) => {
        try {
            await axios.put(`${baseURL}/cart/updateStatus/`, { order_id: orderId, status: newStatus });
            
            if (newStatus === "待換貨") {
                const order = orders.find(o => o.order_id === orderId);
                const extraChargeData = await axios.get(`${baseURL}/users_extraCharges`, { params: { chargename: '換貨工本費' } });
                const extraCharge = extraChargeData.data.charge;
                
                if (extraCharge) {
                    await axios.post(`${baseURL}/order_extraCharges`, {
                        username: order.username,
                        order_id: orderId,
                        extraChargeName: '換貨工本費',
                        extraCharge: extraCharge,
                        created_at: new Date().toISOString()
                    });
                }
            }
            
            fetchOrders(searchParams, activeTab);
        } catch (error) {
            console.error("There was an error updating the order status!", error);
        }
    };
    

    const confirmDeleteOrder = (orderId) => {
        setDeleteOrderId(orderId);
        setShowModal(true);
    };

    const handleDeleteOrder = () => {
        if (deleteOrderId) {
            axios.delete(`${baseURL}/cart/deleteOrder/`, { data: { order_id: deleteOrderId } })
                .then(() => {
                    setShowModal(false);
                    setDeleteOrderId(null);
                    fetchOrders(searchParams, activeTab);
                })
                .catch(error => {
                    console.error("There was an error deleting the order!", error);
                    setShowModal(false);
                });
        }
    };

    const toggleOrderDetails = (orderId) => {
        setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
    };

    const handleShowMessageModal = (orderId) => {
        setSelectedOrderId(orderId);
        setShowMessageModal(true);
    };

    const handleCloseMessageModal = () => {
        setShowMessageModal(false);
        setSelectedOrderId(null);
    };

    const handleShowPictureModal = (orderId) => {
        axios.get(`${baseURL}/order_delivered/picture?order_id=${orderId}`)
            .then(response => {
                setPicture(response.data.picture);
                setShowPictureModal(true);
            })
            .catch(error => {
                console.error("There was an error fetching the delivery picture!", error);
            });
    };

    const handleClosePictureModal = () => {
        setShowPictureModal(false);
        setPicture(null);
    };

    const handleEditInputChange = (e, productId, index) => {
        const { name, value } = e.target;
        const updatedOrderItems = [...editOrderData.order_items];
        updatedOrderItems[index] = {
            ...updatedOrderItems[index],
            [name]: value,
            小計: (name === '數量' || name === '售價') ? (updatedOrderItems[index].數量 * updatedOrderItems[index].售價) : updatedOrderItems[index].小計
        };
        setEditOrderData(prevData => ({
            ...prevData,
            order_items: updatedOrderItems
        }));
    };

    const handleEditShippingChange = (e) => {
        const { name, value } = e.target;
        setEditOrderData(prevData => ({
            ...prevData,
            shipping_info: {
                ...prevData.shipping_info,
                [name]: value
            }
        }));
    };

    const handleEditSave = () => {
        const finalTotal = calculateOrderTotal(editOrderData.order_items);
        const { shippingCost, outlyingShippingCost } = calculateShippingCost(finalTotal, editOrderData.shipping_info.address_line1);
        const totalShippingCost = shippingCost + outlyingShippingCost;
        const finalAmount = Math.round(finalTotal + totalShippingCost);

        const updatedEditOrderData = {
            ...editOrderData,
            final_total: finalAmount
        };

        axios.put(`${baseURL}/cart/updateOrder/`, updatedEditOrderData)
            .then(response => {
                if (response.data.status === 1) {
                    setShowEditModal(false);
                    fetchOrders(searchParams, activeTab);
                } else {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                console.error("There was an error updating the order!", error);
            });
    };

    const calculateExtraCharges = (orderId) => {
        const chargesForOrder = extraCharges[orderId] || [];
        let total = 0;
        let chargeNames = [];
    
        chargesForOrder.forEach(charge => {
            total += parseFloat(charge.extraCharge || 0);
            chargeNames.push(`${charge.extraChargeName}: $${charge.extraCharge}`);
        });
    
        return { total, chargeNames };
    };

    const handleDeleteExtraCharge = (id) => {
        axios.delete(`${baseURL}/order_extraCharges/${id}`)
            .then(response => {
                if (response.data.status === 1) {
                    // 刷新訂單
                    fetchOrders(searchParams, activeTab);
                } else {
                    console.error(response.data.message);
                }
            })
            .catch(error => {
                console.error("There was an error deleting the extra charge!", error);
            });
    };
    
    
    const renderOrders = () => {
        return Object.keys(groupedOrders).sort((a, b) => {
            if (sortOrder === 'desc') {
                return b - a;
            } else {
                return a - b;
            }
        }).map(orderId => {
            const initialTotal = calculateOrderTotal(groupedOrders[orderId]);
            const discountNames = discountData[orderId]?.[0]?.discount_name.split(',') || [];
            const discountRatesAndAmounts = discountData[orderId]?.[0]?.discount_rateAndamount.split(',') || [];
            let totalAfterDiscount = initialTotal;
            let totalDiscount = 0;
    
            discountNames.forEach((name, index) => {
                const discountValue = discountRatesAndAmounts[index];
                const isPercentage = discountValue.includes('%');
                let discountAmount;
    
                if (isPercentage) {
                    const percentage = parseFloat(discountValue) / 100;
                    discountAmount = totalAfterDiscount * percentage;
                } else {
                    discountAmount = parseFloat(discountValue);
                }
    
                totalAfterDiscount -= discountAmount;
                totalDiscount += discountAmount;
            });
    
            const { shippingCost, outlyingShippingCost } = calculateShippingCost(totalAfterDiscount, groupedOrders[orderId][0].address_line1);
            const totalShippingCost = shippingCost + outlyingShippingCost;
            const finalAmount = Math.round(totalAfterDiscount + totalShippingCost);
    
            const { total: extraChargeTotal, chargeNames } = calculateExtraCharges(orderId);
            const finalAmountWithExtraCharge = finalAmount + extraChargeTotal;
    
            return (
                <div className="order-block p-3" key={orderId}>
                    <div className="order-header highlighted p-3 d-flex justify-content-between align-items-center" onClick={() => toggleOrderDetails(orderId)} style={{ cursor: 'pointer' }}>
                        <div>訂單號碼: {orderId}</div>
                        <div className="order-actions d-flex align-items-center">
                            {activeTab === '訂單已完成' && (
                                <>
                                    <i className='bx bx-image' onClick={(e) => { e.stopPropagation(); handleShowPictureModal(orderId); }} style={{ fontSize: '25px', cursor: 'pointer', marginRight: '10px' }} title="查看收貨照片"></i>
                                </>
                            )}
                            <i className='bx bx-message-rounded-dots me-2' onClick={(e) => { e.stopPropagation(); handleShowMessageModal(orderId); }} style={{ fontSize: '25px', cursor: 'pointer' }} title="留言"></i>
                            <select onChange={(e) => handleStatusChange(orderId, e.target.value)} value={groupedOrders[orderId][0].status} className='me-2'>
                                <option value="待付款">待付款</option>
                                <option value="待處理">待處理</option>
                                <option value="待出貨">待出貨</option>
                                <option value="待收貨">待收貨</option>
                                <option value="訂單已完成">訂單已完成</option>
                                <option value="待換貨">待換貨</option>
                            </select>
                            <i className='bx bx-trash me-2' onClick={() => confirmDeleteOrder(orderId)} style={{ cursor: 'pointer', fontSize: '25px' }}></i>
                            <i className='bx bx-edit me-2' onClick={(e) => { e.stopPropagation(); setEditOrderData({
                                order_id: orderId,
                                order_items: groupedOrders[orderId],
                                shipping_info: {
                                    recipient_name: groupedOrders[orderId][0].recipient_name,
                                    email: groupedOrders[orderId][0].email,
                                    mobile: groupedOrders[orderId][0].mobile,
                                    address_line1: groupedOrders[orderId][0].address_line1,
                                    address_line2: groupedOrders[orderId][0].address_line2,
                                    postal_code: groupedOrders[orderId][0].postal_code,
                                    notes: groupedOrders[orderId][0].notes,
                                    storeType: groupedOrders[orderId][0].storeType,
                                    storeAddress: groupedOrders[orderId][0].storeAddress
                                }
                            }); setShowEditModal(true); }} style={{ cursor: 'pointer', fontSize: '25px' }}></i>
                        </div>
                    </div>
                    {expandedOrderId === orderId && (
                        <>
                            <div className="order-recipient p-3">
                                <div>收件人姓名: {groupedOrders[orderId][0].recipient_name}</div>
                                <div>收件人電話: {groupedOrders[orderId][0].mobile}</div>
                                <div>收件人信箱: {groupedOrders[orderId][0].email}</div>
                                <div>收件人地址: {groupedOrders[orderId][0].address_line1}</div>
                                <div>運送方式: {groupedOrders[orderId][0].address_line1 ? "自家住址" : "便利商店"}</div>
                            </div>
                            {groupedOrders[orderId].map(order => (
                                <div className="order-item" key={order.id}>
                                    <img src={process.env.PUBLIC_URL + '/img/' + order.img} alt={order.商品名稱} className="product-img" />
                                    <div className="order-info">
                                        <div className="product-name">{order.商品名稱}</div>
                                        <div className="product-details">
                                            <span className="left-align">
                                                {order.度數 && (<>度數: <span className="left-align-amount">{order.度數}</span><br /></>)}
                                                {order.左眼度數 && (<>左眼度數: <span className="left-align-amount">{order.左眼度數}</span><br /></>)}
                                                {order.右眼度數 && (<>右眼度數: <span className="left-align-amount">{order.右眼度數}</span><br /></>)}
                                                {order.角度 && (<>角度: <span className="left-align-amount">{order.角度}</span><br /></>)}
                                                {order.散光 && (<>散光: <span className="left-align-color">{order.散光}</span><br /></>)}
                                                {order.老花 && (<>老花: <span className="left-align-amount">{order.老花}</span></>)}
                                            </span>
                                            <span className="right-align">${order.售價} x{order.數量}</span>
                                        </div>
                                        <div className="subtotal right-align">${order.小計}</div>
                                    </div>
                                </div>
                            ))}
                            <hr />
                            <div className="order-discount right-align">
                                {discountData[orderId] && discountData[orderId].length > 0 && (() => {
                                    const discountNames = discountData[orderId][0].discount_name.split(',');
                                    const discountRatesAndAmounts = discountData[orderId][0].discount_rateAndamount.split(',');
                                    let totalAfterDiscount = initialTotal;
                                    let totalDiscount = 0;
    
                                    return (
                                        <>
                                            <div>商品總金額: <span className="order-total-amount">${initialTotal.toFixed(2)}</span></div>
                                            {discountNames.map((name, index) => {
                                                const discountValue = discountRatesAndAmounts[index];
                                                const isPercentage = discountValue.includes('%');
                                                let discountAmount;
    
                                                if (isPercentage) {
                                                    const percentage = parseFloat(discountValue) / 100;
                                                    discountAmount = totalAfterDiscount * percentage;
                                                } else {
                                                    discountAmount = parseFloat(discountValue);
                                                }
    
                                                totalAfterDiscount -= discountAmount;
                                                totalDiscount += discountAmount;
                                            })}
                                            <div className="order-discount-b right-align">
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            {discountNames.map((name, index) => (
                                                                <div key={index}>折扣{index + 1}({name}): {discountRatesAndAmounts[index]}</div>
                                                            ))}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="bx bx-info-circle me-1" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                                </OverlayTrigger>
                                                折扣總金額: <span className="order-total-amount">${Math.round(totalDiscount)}</span>
                                            </div>
                                        </>
                                    );
                                })()}
                            </div>
                            <div className="order-shipping right-align">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            <div>基本運費: {shippingCost.toFixed(2)}</div>
                                            {outlyingShippingCost > 0 && (
                                                <div>離島運費: {outlyingShippingCost.toFixed(2)}</div>
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <i className="bx bx-info-circle me-1" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                </OverlayTrigger>
                                運費總金額: <span className="order-total-amount">${totalShippingCost.toFixed(2)}</span>
                            </div>
                            {extraChargeTotal > 0 && (
                                <div className="order-final right-align">
                                    {extraCharges[orderId].map((charge, index) => {
                                        const chargeText = charge.extraChargeName;
                                        const chargeAmount = charge.extraCharge;
                                        return (
                                            <OverlayTrigger
                                                key={index}
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id={`extra-charge-tooltip-${index}`}>
                                                        換貨時間: {new Date(charge.created_at).toLocaleString()}
                                                    </Tooltip>
                                                }
                                            >
                                                <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
                                                    <i className="bx bx-trash me-1" onClick={(e) => { e.stopPropagation(); handleDeleteExtraCharge(charge.id); }} style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                                    <i className="bx bx-info-circle me-1" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                                    {chargeText}: <span style={{ color: '#007bff' }}>${chargeAmount}</span>
                                                </div>
                                            </OverlayTrigger>
                                        );
                                    })}
                                </div>
                            )}
    
                            <div className="order-final right-align">
                                總付款金額: <span className="order-total-amount">${finalAmountWithExtraCharge}</span>
                            </div>
                        </>
                    )}
                </div>
            );
        });
    };
    


    return (
        <>
            <header className="bg-success py-5 mb-5">
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">管理訂單</h1>
                        <div className="p-2">Manager Orders</div>
                    </div>
                </div>
            </header>

            <div className="manager-orders container">
                <div className="tabs">
                    {tabs.map(tab => (
                        <button
                            key={tab}
                            className={activeTab === tab ? 'active' : ''}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                            <span className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${orderCounts[tab] !== 0 ? 'bg-danger' : 'bg-secondary'}`}>
                                {orderCounts[tab]}
                                <span className="visually-hidden">unread messages</span>
                            </span>
                        </button>
                    ))}
                </div>

                <div className="search-bar">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" name="order_id" placeholder="訂單號碼" value={searchParams.order_id} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="recipient_name" placeholder="收件人姓名" value={searchParams.recipient_name} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="address" placeholder="收件人地址" value={searchParams.address} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="mobile" placeholder="收件人電話" value={searchParams.mobile} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="email" placeholder="收件人信箱" value={searchParams.email} onChange={handleInputChange} />
                        <input type="text" className="form-control" name="username" placeholder="使用者" value={searchParams.username} onChange={handleInputChange} />
                        <button className="btn btn-primary" onClick={handleSearch}>搜尋</button>
                        <button onClick={toggleSortOrder} className="btn btn-secondary">
                            {sortOrder === 'desc' ? <i className='bx bx-down-arrow-alt'></i> : <i className='bx bx-up-arrow-alt'></i>}
                        </button>
                    </div>
                </div>

                <div className="order-list">
                    {Object.keys(groupedOrders).length > 0 ? (
                        renderOrders()
                    ) : (
                        <div className="no-orders">沒有找到符合條件的訂單</div>
                    )}
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>確認刪除</Modal.Title>
                </Modal.Header>
                <Modal.Body>您確定要刪除此訂單嗎？此操作無法撤銷。</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        取消
                    </Button>
                    <Button variant="danger" onClick={handleDeleteOrder}>
                        刪除
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPictureModal} onHide={handleClosePictureModal}>
                <Modal.Header closeButton>
                    <Modal.Title>收貨照片</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {picture ? (
                        <img src={picture} alt="收貨照片" style={{ width: '100%' }} />
                    ) : (
                        <p>沒有上傳的收貨照片。</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePictureModal}>
                        關閉
                    </Button>
                </Modal.Footer>
            </Modal>

            {selectedOrderId && (
                <OrderMessage
                    orderId={selectedOrderId}
                    show={showMessageModal}
                    handleClose={handleCloseMessageModal}
                />
            )}

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>編輯訂單</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editOrderData && (
                        <>
                            <Form>
                                <h5>商品項目</h5>
                                {editOrderData.order_items.map((item, index) => (
                                    <div key={item.id} className="order-item p-2 mb-3 border rounded">
                                        <img src={process.env.PUBLIC_URL + '/img/' + item.img} alt={item.商品名稱} className="product-img" />
                                        <Form.Group className="mb-2">
                                            <Form.Label>商品名稱</Form.Label>
                                            <Form.Control type="text" readOnly value={item.商品名稱} />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>數量</Form.Label>
                                            <Form.Control type="number" name="數量" value={item.數量} onChange={(e) => handleEditInputChange(e, item.product_id, index)} />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>售價</Form.Label>
                                            <Form.Control type="number" name="售價" value={item.售價} onChange={(e) => handleEditInputChange(e, item.product_id, index)} />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>小計</Form.Label>
                                            <Form.Control type="number" readOnly value={(item.數量 * item.售價).toFixed(2)} />
                                        </Form.Group>
                                    </div>
                                ))}
                                <h5>配送資訊</h5>
                                <Form.Group className="mb-2">
                                    <Form.Label>收件人姓名</Form.Label>
                                    <Form.Control type="text" name="recipient_name" value={editOrderData.shipping_info.recipient_name} onChange={handleEditShippingChange} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>收件人信箱</Form.Label>
                                    <Form.Control type="email" name="email" value={editOrderData.shipping_info.email} onChange={handleEditShippingChange} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>收件人電話</Form.Label>
                                    <Form.Control type="text" name="mobile" value={editOrderData.shipping_info.mobile} onChange={handleEditShippingChange} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>收件人地址</Form.Label>
                                    <Form.Control type="text" name="address_line1" value={editOrderData.shipping_info.address_line1} onChange={handleEditShippingChange} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>郵遞區號</Form.Label>
                                    <Form.Control type="text" name="postal_code" value={editOrderData.shipping_info.postal_code} onChange={handleEditShippingChange} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>備註</Form.Label>
                                    <Form.Control type="text" name="notes" value={editOrderData.shipping_info.notes} onChange={handleEditShippingChange} />
                                </Form.Group>
                            </Form>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={handleEditSave}>
                        儲存
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};


export default ManagerOrders;
