import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from './apiConfig.js';
import styles from '../css/SystemSetting.module.css'; // 确保导入模块化 CSS 文件

const SettingOthers = () => {
    const [remittances, setRemittances] = useState([]);
    const [form, setForm] = useState({
        id: null,
        seller_id: '',
        bank_name: '',
        bank_code: '',
        account_name: '',
        account_number: ''
    });

    useEffect(() => {
        fetchRemittances();
    }, []);

    const fetchRemittances = async () => {
        try {
            const response = await axios.get(`${baseURL}/set_RemittanceManager`);
            setRemittances(response.data);
        } catch (error) {
            console.error('Error fetching remittances:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseURL}/set_RemittanceManager`, form);
            if (response.data.status === 1) {
                alert('更新完畢');
                fetchRemittances();
                setForm({
                    id: null,
                    seller_id: '',
                    bank_name: '',
                    bank_code: '',
                    account_name: '',
                    account_number: ''
                });
            } else {
                alert('發生錯誤，請稍後再試');
            }
        } catch (error) {
            console.error('Error saving remittance info:', error);
        }
    };

    const handleEdit = (remittance) => {
        setForm(remittance);
    };

    const handleDelete = async (id) => {
        if (window.confirm('您確定要刪除此筆資料?')) {
            try {
                const response = await axios.delete(`${baseURL}/set_RemittanceManager/${id}`);
                if (response.data.status === 1) {
                    alert('更新完畢');
                    fetchRemittances();
                } else {
                    alert('發生錯誤，請稍後再試');
                }
            } catch (error) {
                console.error('Error deleting remittance info:', error);
            }
        }
    };

    return (
        <div className={`${styles.systemSettingContainer} mb-5`}>
            <div className="row">
                <div className="col-md-6">
                    <h2>其他設定</h2>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.formGroup}>
                            <label>用戶登入帳號 (填0 = 全系統通用 , 填帳號 = 該帳號顯示此設定) <span className={styles.required}>*</span></label>
                            <input type="text" name="seller_id" value={form.seller_id} onChange={handleChange} required />
                        </div>
                        <div className={styles.formGroup}>
                            <label>銀行名稱 <span className={styles.required}>*</span></label>
                            <input type="text" name="bank_name" value={form.bank_name} onChange={handleChange} required />
                        </div>
                        <div className={styles.formGroup}>
                            <label>銀行代號 <span className={styles.required}>*</span></label>
                            <input type="text" name="bank_code" value={form.bank_code} onChange={handleChange} required />
                        </div>
                        <div className={styles.formGroup}>
                            <label>戶名 <span className={styles.required}>*</span></label>
                            <input type="text" name="account_name" value={form.account_name} onChange={handleChange} required />
                        </div>
                        <div className={styles.formGroup}>
                            <label>帳號 <span className={styles.required}>*</span></label>
                            <input type="text" name="account_number" value={form.account_number} onChange={handleChange} required minLength="10" maxLength="14" />
                        </div>
                        <button type="submit" className={styles.saveButton}>儲存銀行資訊</button>
                    </form>
                </div>
                <div className="col-md-6">
                    <h3>銀行帳戶資訊</h3>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>帳戶ID</th>
                                <th>銀行名稱</th>
                                <th>銀行代號</th>
                                <th>戶名</th>
                                <th>帳號</th>
                                <th>動作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {remittances.map((remittance) => (
                                <tr key={remittance.id}>
                                    <td>{remittance.id}</td>
                                    <td>{remittance.seller_id}</td>
                                    <td>{remittance.bank_name}</td>
                                    <td>{remittance.bank_code}</td>
                                    <td>{remittance.account_name}</td>
                                    <td>{remittance.account_number}</td>
                                    <td>
                                        <button onClick={() => handleEdit(remittance)} className={styles.editButton}>編輯</button>
                                        <button onClick={() => handleDelete(remittance.id)} className={styles.deleteButton}>刪除</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SettingOthers;
