import React, { useState } from 'react';
import Remittance from './setting-Remittance';
import Others from './setting-Others';
import DiscountSettings from './setting-discountSettings';
import ShippingCostSettings from './setting-shippingCostSettings';
import ExtraCharges from './setting-extraCharges'; // 引入 ExtraCharges 組件
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import styles from '../css/SystemSetting.module.css';

const SystemSetting = () => {
    const [activeSection, setActiveSection] = useState('bank-management');

    const handleMenuClick = (section) => {
        setActiveSection(section);
    };

    const navbarStyle = {
        backgroundColor: '#46696b',
        padding: '20px',
    };

    const navLinkStyle = {
        color: 'white',
    };

    return (
        <>
            <header className="bg-success py-5">
                <div className="container-fluid px-4 px-lg-5 my-4">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">系統設定</h1>
                        <div className="p-2">System Setting</div>
                    </div>
                </div>
            </header>
            <Navbar expand="md" className="w-100" style={navbarStyle}>
                <Nav className="mx-auto">
                    <Nav.Link
                        className={activeSection === 'bank-management' ? styles.active : ''}
                        onClick={() => handleMenuClick('bank-management')}
                        style={navLinkStyle}
                    >
                        銀行
                    </Nav.Link>
                    <Nav.Link
                        className={activeSection === 'discountSettings-management' ? styles.active : ''}
                        onClick={() => handleMenuClick('discountSettings-management')}
                        style={navLinkStyle}
                    >
                        折扣
                    </Nav.Link>
                    <Nav.Link
                        className={activeSection === 'shippingCost-management' ? styles.active : ''}
                        onClick={() => handleMenuClick('shippingCost-management')}
                        style={navLinkStyle}
                    >
                        運費
                    </Nav.Link>
                    <Nav.Link
                        className={activeSection === 'extraCharges-management' ? styles.active : ''}
                        onClick={() => handleMenuClick('extraCharges-management')}
                        style={navLinkStyle}
                    >
                        其他費用
                    </Nav.Link>
                </Nav>
            </Navbar>
            <div className={`mt-5 justify-content-center w-100 container-fluid ${styles.systemSettingContainer}`}>
                <div className="d-flex justify-content-center w-100">
                    <div className={`col-md-2 ${styles.menuColumn}`}>
                        {/* 左側留空或放置其他內容 */}
                    </div>
                    <div className={`col-md-10 ${styles.contentColumn}`}>
                        <div style={{ minHeight: '500px' }}>
                            {activeSection === 'bank-management' && <Remittance />}
                            {activeSection === 'account-management' && <Others />}
                            {activeSection === 'discountSettings-management' && <DiscountSettings />}
                            {activeSection === 'shippingCost-management' && <ShippingCostSettings />}
                            {activeSection === 'extraCharges-management' && <ExtraCharges />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SystemSetting;
