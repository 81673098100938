import React, { useState } from 'react';
import axios from 'axios';
import baseURL from './manager/apiConfig';
import './css/login.css';
import { Modal, Button } from 'react-bootstrap';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e) => {
    console.log("表單提交觸發");
    e.preventDefault();

    console.log("登入帳號：", username);

    axios.get(`${baseURL}/userLogin/hi`, {
      params: {
        username: username,
        password: password
      }
    })
    .then(response => {
      if (response.data.status === 0) {
        setError('使用者名稱或密碼錯誤');
        console.log("回傳訊息：", response.data);
        setIsModalOpen(true); // 顯示錯誤訊息 Modal
      } else {
        setError('');
        localStorage.removeItem('username');
        localStorage.setItem('username', username);
        localStorage.setItem('perm', response.data.perm);
        console.log(" response.data.perm：", response.data.perm);
        localStorage.setItem('isLoggedIn', 'true');
        onLogin();
        // 使用react route 重新導向到 / 首頁
        window.location.href = '/';
      }
    })
    .catch(error => {
      console.error('登入請求錯誤:', error);
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="login-form bg-dark wrapper d-flex align-items-center justify-content-center w-100">
      <div className="login">
        <h2 className='mb-3'>E世代</h2>
        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>錯誤</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
        <form onSubmit={(e) => handleSubmit(e)} className='needs-validation'>
          <div className="form-group was-validated mb-2">
            <label htmlFor='usernameInput' className='form-label'>使用者名稱:</label>
            <input
              type="text"
              className='form-control'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder='測試帳號: abc'
              required
            />
            <div className="invalid-feedback">請輸入使用者名稱</div>
          </div>
          <div className="form-group was-validated mb-2">
            <label htmlFor='password' className='form-label'>密碼:</label>
            <input
              type="password"
              className='form-control'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='測試密碼: aaa'
              required
            />
            <div className="invalid-feedback">請輸入密碼</div>
          </div>
          <div className="form-group form-check mb-2">
            <input
              type="checkbox"
              id='rememberMe'
              className='form-check-input'
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem('rememberMe', 'true');
                } else {
                  localStorage.removeItem('rememberMe');
                }
              }}
            />
            <label htmlFor='rememberMe' className='form-check-label'>記住我</label>
          </div>
          <button type="submit" className='btn btn-success w-100 mt-2'>登入</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
