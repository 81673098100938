import React from 'react';
import CreateProduct from './CreateProduct';
import ListProducts from './ListProducts';
import ModifyProduct from './ModifyProduct'; // 引入 ModifyProduct 組件
import CategoryManagement from './CategoryManagement';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import styles from '../css/ProductManagement.module.css';
import { ProductManagementProvider, useProductManagement } from './ProductManagementContext';

const ProductManagement = () => {
    return (
        <ProductManagementProvider>
            <ProductManagementContent />
        </ProductManagementProvider>
    );
};

const ProductManagementContent = () => {
    const { activeSection, handleMenuClick } = useProductManagement();

    const navbarStyle = {
        backgroundColor: '#46696b',
        padding: '20px',
    };

    const navLinkStyle = {
        color: 'white',
    };

    return (
        <>
            <header className="bg-success py-5">
                <div className="container-fluid px-4 px-lg-5 my-4">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">產品管理</h1>
                        <div className="p-2">Product Management</div>
                    </div>
                </div>
            </header>
            <Navbar expand="md" className="w-100" style={navbarStyle}>
                <Nav className="mx-auto">
                    <Nav.Link
                        className={activeSection === 'create-product' ? styles.active : ''}
                        onClick={() => handleMenuClick('create-product')}
                        style={navLinkStyle}
                    >
                        新增
                    </Nav.Link>
                    <Nav.Link
                        className={activeSection === 'list-products' ? styles.active : ''}
                        onClick={() => handleMenuClick('list-products')}
                        style={navLinkStyle}
                    >
                        查詢
                    </Nav.Link>
                    <Nav.Link
                        className={activeSection === 'category-management' ? styles.active : ''}
                        onClick={() => handleMenuClick('category-management')}
                        style={navLinkStyle}
                    >
                        分類
                    </Nav.Link>
                </Nav>
            </Navbar>
            <div className={`mt-5 justify-content-center w-100 container-fluid ${styles.productManagementContainer}`}>
                <div className="d-flex justify-content-center w-100">
                    <div className={`col-md-2 ${styles.menuColumn}`}>
                        {/* 左側留空或放置其他內容 */}
                    </div>
                    <div className={`col-md-10 ${styles.contentColumn}`}>
                        <div style={{ minHeight: '500px' }}>
                            {activeSection === 'create-product' && <CreateProduct />}
                            {activeSection === 'list-products' && <ListProducts />}
                            {activeSection === 'category-management' && <CategoryManagement />}
                            {activeSection === 'modify-product' && <ModifyProduct />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductManagement;
