import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import baseURL from "./apiConfig.js";

export default function ListUser() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({});

    const {id} = useParams();

    useEffect(() => {
        const getUser = () => {
            axios.get(`${baseURL}/userCRUD/${id}`).then(function(response) {
                console.log(response.data);
                setInputs(response.data);
            });
        };

        getUser(); // 在 useEffect 內部調用 getUser，確保只運行一次

        // 注意: 這裡不需要將 getUser 函式添加到依賴陣列中

    }, [id]); // id 是 useEffect 的依賴，因此將其添加到依賴陣列中以確保在 id 更改時重新運行 useEffect

    // function getUser() {
    //     axios.get(`${baseURL}/user/${id}`).then(function(response) {
    //         console.log(response.data);
    //         setInputs(response.data);
    //     });
    // }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        axios.put(`${baseURL}/userCRUD/${id}/edit`, inputs).then(function(response){
            console.log(response.data);
            navigate('/manager/ListUser');
        });
        
    }
    return (
        <div>
            <h1>Edit user</h1>
            <form onSubmit={handleSubmit}>
                <table cellSpacing="10">
                    <tbody>
                        <tr>
                            <th>
                                <label>Name: </label>
                            </th>
                            <td>
                                <input value={inputs.name} type="text" name="name" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Email: </label>
                            </th>
                            <td> 
                                <input value={inputs.email} type="text" name="email" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Mobile: </label>
                            </th>
                            <td>
                                <input value={inputs.mobile} type="text" name="mobile" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align ="right">
                                <button>Save</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}
