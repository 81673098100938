import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FloatingLabel, Alert, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import baseURL from './apiConfig';

const ProductForm = ({
  product,
  errors,
  setErrors,
  setProduct,
  handleChange,
  handleSubmit,
  imageError,
  productImages,
  imagePreviews,
  handleRemoveProductImage,
  handleSetMainProductImage,
  handleProductImagesDrop,
  featureImages,
  featureImagePreviews,
  handleRemoveFeatureImage,
  handleFeatureImagesDrop,
}) => {
  const [categories, setCategories] = useState([]);
  const fieldLengths = {
    text: 255,
    youtubeUrl: 200,
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      分類: (prevProduct.分類 || []).filter(Boolean), // 初始化時過濾掉無效值
    }));
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseURL}/product_categoriesCRUD`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    let error = '';

    if (value.length > fieldLengths[name]) {
      error = `此欄位最大長度為 ${fieldLengths[name]} 個字`;
    } else if (['售價', '福利價', '最低數量'].includes(name) && Number(value) < 0) {
      error = '數字必須大於 0';
    }

    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
      if (['text', 'youtubeUrl'].includes(name)) {
        setProduct((prevProduct) => ({
          ...prevProduct,
          產品特色詳細: {
            ...prevProduct.產品特色詳細,
            [name]: formattedValue,
          },
        }));
      } else {
        setProduct((prevProduct) => ({ ...prevProduct, [name]: formattedValue }));
      }
    }
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    let selectedCategories = [...product.分類].filter(Boolean);

    if (checked) {
      if (!selectedCategories.includes(value)) {
        selectedCategories.push(value); // 只添加新值
      }
    } else {
      const index = selectedCategories.indexOf(value);
      if (index > -1) {
        selectedCategories.splice(index, 1); // 移除該值
      }
    }

    // 處理組合商品邏輯
    if (value === '組合商品') {
      setProduct((prevProduct) => ({
        ...prevProduct,
        組合商品: checked ? true : false,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        分類: selectedCategories,
      }));
    }

    console.log('Updated categories:', selectedCategories);
  };

  const renderFormGroup = (
    label,
    name,
    value,
    type = 'text',
    as = 'input',
    required = false,
    handleChangeFunc = handleInputChange,
    colWidth = 12
  ) => (
    <Form.Group as={Col} lg={colWidth} controlId={`formGrid${name}`} key={name}>
      <FloatingLabel
        label={
          <span>
            {label}
            {required && <span style={{ color: 'red' }}> *</span>}
          </span>
        }
      >
        <Form.Control
          type={type}
          name={name}
          value={value || ''} // 確保value始終是一个字符串
          onChange={handleChangeFunc}
          placeholder={`請輸入${label}`}
          required={required}
          as={as}
          rows={as === 'textarea' ? 4 : undefined}
          isInvalid={!!errors[name]}
          style={
            name === '產品詳述'
              ? { whiteSpace: 'pre-wrap', minHeight: '150px' }
              : name === 'text'
              ? { whiteSpace: 'pre-wrap', minHeight: '200px' }
              : name === 'youtubeUrl'
              ? { whiteSpace: 'pre-wrap' }
              : undefined
          }
        />
        <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );

  const renderSelectGroup = (
    label,
    name,
    value,
    options,
    required = false,
    handleChangeFunc = handleInputChange,
    colWidth = 12
  ) => (
    <Form.Group as={Col} lg={colWidth} controlId={`formGrid${name}`} key={name}>
      <FloatingLabel
        label={
          <span>
            {label}
            {required && <span style={{ color: 'red' }}> *</span>}
          </span>
        }
      >
        <Form.Select
          name={name}
          value={value}
          onChange={handleChangeFunc}
          required={required}
          isInvalid={!!errors[name]}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );

  const renderCategoryCheckboxes = (categories, parent = null, depth = 0) => {
    const filteredCategories = categories.filter((category) => category.parent_id === parent);
  
    if (filteredCategories.length === 0) {
      return null;
    }
  
    const groupedCategories = [];
    for (let i = 0; i < filteredCategories.length; i += 2) {
      groupedCategories.push(filteredCategories.slice(i, i + 2));
    }
  
    return groupedCategories.map((group, rowIndex) => (
      <Row key={`category-row-${rowIndex}`} className="mb-2">
        {group.map((category) => {
          const inputId = `category-${category.id}`;
          const isMainCategory = category.parent_id === null;
  
          return (
            <Col key={category.id} md={6} style={{ marginLeft: `${depth * 20}px` }}>
              <div>
                {isMainCategory ? (
                  <Form.Label className="fw-bold">{category.name}</Form.Label>
                ) : (
                  <Form.Check
                    type="checkbox"
                    id={inputId}
                    label={category.name}
                    value={category.id}
                    checked={product.分類 && product.分類.includes(category.id.toString())}
                    onChange={handleCategoryChange}
                    htmlFor={inputId}
                  />
                )}
                {renderCategoryCheckboxes(categories, category.id, depth + 1)}
              </div>
            </Col>
          );
        })}
      </Row>
    ));
  };
  

  const { getRootProps: getProductRootProps, getInputProps: getProductInputProps } = useDropzone({
    onDrop: handleProductImagesDrop,
    accept: 'image/*',
  });

  const { getRootProps: getFeatureRootProps, getInputProps: getFeatureInputProps } = useDropzone({
    onDrop: handleFeatureImagesDrop,
    accept: 'image/*',
  });

  const renderImagePreviews = (images, previews, handleRemove, handleSetMain) => {
    return previews.map((src, index) => (
      <li key={index} className="image-item">
        <img src={src} alt={`Preview ${index}`} />
        <div className="image-info">
          <i
            className="bx bx-trash"
            style={{ fontSize: '20px' }}
            type="button"
            onClick={() => handleRemove(index)}
          ></i>
          {handleSetMain && !images[index]?.isMain && (
            <button type="button" onClick={() => handleSetMain(index)}>
              設為主圖
            </button>
          )}
          {images[index]?.isMain && (
            <i className="bx bxs-star" style={{ color: '#ff0000', fontSize: '25px' }}></i>
          )}
        </div>
      </li>
    ));
  };

  return (
    <Form onSubmit={handleSubmit} className="product-form">
      {imageError && <Alert variant="danger">{imageError}</Alert>}
      <Row className="mb-3">
        <Form.Group as={Col} lg={12} controlId="formGridCategories">
          <Form.Label>分類</Form.Label>
          {renderCategoryCheckboxes(categories)}
          <Form.Control.Feedback type="invalid">{errors.分類}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        {renderFormGroup('廠牌', '廠牌', product.廠牌, 'text', 'input', true, handleInputChange, 4)}
        {renderFormGroup('商品名稱', '商品名稱', product.商品名稱, 'text', 'input', true, handleInputChange, 8)}
      </Row>
      <Row className="mb-3">
        {renderFormGroup('弧度', '弧度', product.弧度, 'text', 'input', true, handleInputChange, 4)}
        {renderFormGroup('包片數', '包片數', product.包片數, 'text', 'input', true, handleInputChange, 4)}
        {renderFormGroup('含水量', '含水量', product.含水量, 'text', 'input', true, handleInputChange, 4)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('度數(每筆度數請用,隔開)', '度數', product.度數, 'text', 'input', false, handleInputChange, 12)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('左眼度數(每筆度數請用,隔開)', '左眼度數', product.左眼度數, 'text', 'input', false, handleInputChange, 12)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('右眼度數(每筆度數請用,隔開)', '右眼度數', product.右眼度數, 'text', 'input', false, handleInputChange, 12)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('散光(每筆度數請用,隔開)', '散光', product.散光, 'text', 'input', false, handleInputChange, 12)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('角度(每筆度數請用,隔開)', '角度', product.角度, 'text', 'input', false, handleInputChange, 12)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('老花(每筆度數請用,隔開)', '老花', product.老花, 'text', 'input', false, handleInputChange, 12)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('顏色種類(每筆度數請用,隔開)', '顏色種類', product.顏色種類, 'text', 'input', true)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('鏡面直徑', '鏡面直徑', product.鏡面直徑, 'text', 'input', true, handleInputChange, 4)}
        {renderFormGroup('著色直徑', '著色直徑', product.著色直徑, 'text', 'input', false, handleInputChange, 4)}
        {renderFormGroup('產地', '產地', product.產地, 'text', 'input', false, handleInputChange, 4)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('售價', '售價', product.售價, 'number', 'input', true, handleInputChange, 4)}
        {renderFormGroup('福利價(預設0)', '福利價', product.福利價, 'number', 'input', false, handleInputChange, 4)}
        {renderFormGroup('最低數量', '最低數量', product.最低數量, 'number', 'input', true, handleInputChange, 4)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('注意事項', '注意事項', product.注意事項, 'text', 'input', false, handleInputChange, 6)}
        {renderFormGroup('產品特色', '產品特色', product.產品特色, 'text', 'input', false, handleInputChange, 6)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('產品詳述', '產品詳述', product.產品詳述, 'textarea', 'textarea', false, handleInputChange, 12)}
      </Row>

      <Row className="mb-3">
        {renderFormGroup('顯示組別', '顯示組別', product.顯示組別, 'text', 'input', false, handleInputChange, 4)}
        {renderSelectGroup(
          '停用',
          '停用',
          product.停用,
          [
            { value: '0', label: '否' },
            { value: '1', label: '是' },
          ],
          false,
          handleInputChange,
          4
        )}
      </Row>

      <div {...getProductRootProps()} className="dropzone">
        <input {...getProductInputProps()} />
        <p>請將"產品圖"拖到此處</p>
      </div>
      {imagePreviews.length > 0 && (
        <div className="image-preview">
          <h2>產品圖</h2>
          <div className="image-grid">
            {renderImagePreviews(productImages, imagePreviews, handleRemoveProductImage, handleSetMainProductImage)}
          </div>
        </div>
      )}

      <Row className="mt-3 mb-3">
        {renderFormGroup('文字介紹', 'text', product.產品特色詳細.text, 'textarea', 'textarea', false)}
      </Row>

      <Row className="mt-3 mb-3">
        {renderFormGroup('YouTube 連結', 'youtubeUrl', product.產品特色詳細.youtubeUrl, 'url', 'input', false)}
      </Row>

      <div {...getFeatureRootProps()} className="dropzone">
        <input {...getFeatureInputProps()} />
        <p>請將"詳細產品介紹圖"拖到此處</p>
      </div>

      {featureImagePreviews.length > 0 && (
        <div className="image-preview">
          <h2>選擇的詳細產品介紹圖</h2>
          <div className="image-grid">
            {renderImagePreviews(featureImages, featureImagePreviews, handleRemoveFeatureImage)}
          </div>
        </div>
      )}

      <Button type="submit" className="submit-button">
        送出
      </Button>
    </Form>
  );
};

export default ProductForm;
