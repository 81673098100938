import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from './apiConfig';
import '../css/ListProducts.css';
import { useProductManagement } from './ProductManagementContext';
import ReactPaginate from 'react-paginate';

const ListProducts = () => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const productsPerPage = 20;
    const { handleMenuClick } = useProductManagement();

    useEffect(() => {
        fetchProducts();
    }, [currentPage, searchQuery]);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${baseURL}/productCRUD`);
            let filteredProducts = response.data;

            if (searchQuery) {
                filteredProducts = filteredProducts.filter(product =>
                    product['廠牌'].includes(searchQuery) ||
                    product['商品名稱'].includes(searchQuery) ||
                    product['顏色種類'].includes(searchQuery)
                );
            }

            setPageCount(Math.ceil(filteredProducts.length / productsPerPage));
            setProducts(filteredProducts.slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage));
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const handleSelectProduct = (id) => {
        setSelectedProducts((prevSelected) => 
            prevSelected.includes(id) ? prevSelected.filter(productId => productId !== id) : [...prevSelected, id]
        );
    };

    const handleDeleteSelected = async () => {
        if (selectedProducts.length === 0) {
            alert('請先選擇要刪除的商品');
            return;
        }

        if (window.confirm('確定要刪除所選擇的商品嗎？')) {
            try {
                const response = await axios.post(`${baseURL}/productCRUD/batchDelete`, { product_ids: selectedProducts });
                console.log('Batch delete response:', response.data);
                alert('批量刪除成功');
                fetchProducts();
                setSelectedProducts([]);
            } catch (error) {
                console.error('Error deleting products:', error);
                alert('刪除發生錯誤: ' + error.message);
            }
        }
    };

    const navigateToModifyProduct = (id) => {
        handleMenuClick('modify-product', id);
    };

    return (
        <>
            <div className="container"> 
                <input
                    type="text"
                    placeholder="搜尋廠牌、商品名稱或顏色種類"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-box"
                />
                <div className="button-container">
                    <button className="batch-delete-button" onClick={handleDeleteSelected}>刪除</button>
                </div>
                <table className="product-table">
                    <thead>
                        <tr>
                            <th><input type="checkbox" onChange={(e) => setSelectedProducts(e.target.checked ? products.map(p => p.id) : [])} /></th>
                            <th>廠牌</th>
                            <th>商品名稱</th>
                            <th>顏色種類</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) => (
                            <tr key={product.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedProducts.includes(product.id)}
                                        onChange={() => handleSelectProduct(product.id)}
                                    />
                                </td>
                                <td>{product['廠牌']}</td>
                                <td>{product['商品名稱']}</td>
                                <td>{product['顏色種類']}</td>
                                <td>
                                    <button className="edit-button" onClick={() => navigateToModifyProduct(product.id)}>編輯</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <ReactPaginate
                    previousLabel={<i className='bx bx-chevron-left'></i>}
                    nextLabel={<i className='bx bx-chevron-right'></i>}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </>
    );
};

export default ListProducts;
