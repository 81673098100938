import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import baseURL from './apiConfig';
import '../css/CreateProduct.css';
import { Modal, Button, FloatingLabel, Form, Row, Col, Alert } from 'react-bootstrap';
import ProductForm from './ProductForm';

const CreateProduct = () => {
  const initialProductState = {
    廠牌: '',
    商品名稱: '',
    弧度: '',
    含水量: '',
    鏡面直徑: '',
    著色直徑: '',
    度數: '',
    顏色種類: '',
    包片數: '',
    注意事項: '',
    售價: '0',
    福利價: '0',
    福利價start: '',
    福利價end: '',
    產地: '',
    產品特色: '',
    產品詳述: '',
    最低數量: '',
    許可證字號: '',
    停用: '0',
    顯示組別: '',
    產地: '',
    散光: '',
    角度: '',
    老花: '',
    分類: '',
    產品特色詳細: {
      text: '',
      youtubeUrl: ''
    }
  };

  const [product, setProduct] = useState(initialProductState);
  const [productImages, setProductImages] = useState([]);
  const [featureImages, setFeatureImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [featureImagePreviews, setFeatureImagePreviews] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [imageError, setImageError] = useState('');
  const [mainImageChanged, setMainImageChanged] = useState(false);

  const onDrop = (acceptedFiles, callback) => {
    const compressImage = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const maxWidth = 1200;
            const maxHeight = 1200;

            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              resolve(new File([blob], file.name, { type: file.type }));
            }, file.type, 0.7);
          };
        };
      });
    };

    const compressAllImages = async () => {
      const compressedImages = await Promise.all(
        acceptedFiles.map((file) => compressImage(file))
      );
      callback(compressedImages);
    };

    compressAllImages();
  };

const handleProductImagesDrop = (acceptedFiles) => {
  onDrop(acceptedFiles, (compressedImages) => {
    const newProductImages = compressedImages.map((file, index) => ({
      file,
      isNew: true,
      isMain: index === 0, // 第一张图片设为主图
    }));
    setProductImages((prevImages) => [...prevImages, ...newProductImages]);

    const previewUrls = compressedImages.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...previewUrls]);
    setImageError('');
    setMainImageChanged(true); // 确保标记主图已更改
  });
};


  const handleFeatureImagesDrop = (acceptedFiles) => {
    onDrop(acceptedFiles, (compressedImages) => {
      const newFeatureImages = compressedImages.map((file) => ({
        file,
        isNew: true,
      }));
      setFeatureImages((prevImages) => [...prevImages, ...newFeatureImages]);

      const previewUrls = compressedImages.map((file) => URL.createObjectURL(file));
      setFeatureImagePreviews((prevPreviews) => [...prevPreviews, ...previewUrls]);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: value
    }));
  };

  const handleRemoveProductImage = (index) => {
    const updatedProductImages = productImages.filter((_, i) => i !== index);
    const updatedImagePreviews = imagePreviews.filter((_, i) => i !== index);

    if (productImages[index].isMain && updatedProductImages.length > 0) {
      updatedProductImages[0].isMain = true;
      setMainImageChanged(true);
    }

    setProductImages(updatedProductImages);
    setImagePreviews(updatedImagePreviews);
  };

  const handleRemoveFeatureImage = (index) => {
    handleRemoveImage(index, setFeatureImages, setFeatureImagePreviews, featureImages, featureImagePreviews);
  };

  const handleRemoveImage = (indexToRemove, setImageFunc, setPreviewFunc, images, previews) => {
    setImageFunc(images.filter((_, index) => index !== indexToRemove));
    setPreviewFunc(previews.filter((_, index) => index !== indexToRemove));
  };

  const handleSetMainProductImage = (index) => {
    handleSetMainImage(index, setProductImages, productImages);
    setMainImageChanged(true);
  };

  const handleSetMainImage = (index, setImageFunc, images) => {
    const updatedImages = images.map((image, i) => ({
      ...image,
      isMain: i === index
    }));
    setImageFunc(updatedImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (productImages.length === 0) {
      setImageError('請選擇至少一張產品圖');
      setModalMessage('請選擇至少一張產品圖');
      setModalShow(true);
      return;
    }

    const formData = new FormData();
    formData.append('product', JSON.stringify(product));
    formData.append('mainImageChanged', mainImageChanged);
    formData.append('mainImageFileName', productImages.find(image => image.isMain)?.path || '');

    productImages.forEach((image, index) => {
      formData.append(`productImages[${index}]`, image.file);
      formData.append(`productImages[${index}][isNew]`, image.isNew);
      formData.append(`productImages[${index}][isMain]`, image.isMain);
    });

    featureImages.forEach((image, index) => {
      formData.append(`featureImages[${index}]`, image.file);
      formData.append(`featureImages[${index}][isNew]`, image.isNew);
    });

    for (let pair of formData.entries()) {
      if (pair[0] === 'product') {
        console.log(`${pair[0]}: ${JSON.stringify(JSON.parse(pair[1]), null, 2)}`);
      } else {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
    }

    try {
      const response = await axios.post(`${baseURL}/productCRUD/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.status === 1) {
        setModalMessage("成功" + JSON.stringify(response.data));
        setProduct(initialProductState);
        setProductImages([]);
        setFeatureImages([]);
        setImagePreviews([]);
        setFeatureImagePreviews([]);
      } else if (response.data.status === 0) {
        setModalMessage("失敗" + JSON.stringify(response.data));
      } else {
        setModalMessage("其他" + JSON.stringify(response.data));
      }
      setModalShow(true);
    } catch (error) {
      console.error('Error:', error);
      setModalMessage('新增失敗');
      setModalShow(true);
    }
  };

  return (
    <div>
      <div className="container">
        <ProductForm
          product={product}
          errors={errors}
          setErrors={setErrors}
          setProduct={setProduct} // 確保傳遞 setProduct
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          imageError={imageError}
          productImages={productImages}
          imagePreviews={imagePreviews}
          handleRemoveProductImage={handleRemoveProductImage}
          handleSetMainProductImage={handleSetMainProductImage}
          handleProductImagesDrop={handleProductImagesDrop}
          featureImages={featureImages}
          featureImagePreviews={featureImagePreviews}
          handleRemoveFeatureImage={handleRemoveFeatureImage}
          handleFeatureImagesDrop={handleFeatureImagesDrop}
        />

        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>產品管理</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CreateProduct;
