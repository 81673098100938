import React, { useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CartContext } from "./CartContext";

export default function ItemDelBtn({ productInfo }) {
  const username = localStorage.getItem('username');
  const { cartItems, setCartItems } = useContext(CartContext);
  const [showModal, setShowModal] = useState(false); // 控制模态窗口的显示

  const handleDelete = () => {
    let newCartItems = cartItems.filter((item) => item.id !== productInfo.id || item.degree !== productInfo.degree); // 用 filter 过滤掉要删除的商品
    setCartItems(newCartItems);
    localStorage.setItem(`${username}_cartItems`, JSON.stringify(newCartItems));
    setShowModal(false); // 关闭模态窗口
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      {/* 载入 /icon/trash.svg , 让鼠标移上出现光标 */}
      <i class='bx bx-x' alt="delete" style={{cursor:"pointer",width:"20px",fontSize:"24px"}} onClick={handleShowModal}></i>

      {/* 确认删除的模态窗口 */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>確認刪除</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          您確定要刪除此商品嗎？
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDelete}>
            確定
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            取消
          </Button> 
        </Modal.Footer>
      </Modal>
    </div>
  );
}
