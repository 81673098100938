import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from './apiConfig.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from '../css/CategoryManagement.module.css';

const CategoryManagement = () => {
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState('');
    const [parent, setParent] = useState('');
    const [editId, setEditId] = useState(null);
    const [editName, setEditName] = useState('');
    const [editParent, setEditParent] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseURL}/product_categoriesCRUD`);
            console.log(response.data); // 打印返回數據，確認其結構
            if (Array.isArray(response.data)) {
                setCategories(response.data);
            } else {
                console.error('Error: response data does not contain an array', response.data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    
    

    const handleSave = async () => {
        const newCategory = { name, parent_id: parent || null };
        try {
            const response = await axios.post(`${baseURL}/product_categoriesCRUD`, newCategory);
            if (response.data.status === 1) {
                fetchCategories();
                setName('');
                setParent('');
            } else {
                console.error('Error adding category:', response.data.message);
            }
        } catch (error) {
            console.error('Error saving category:', error);
        }
    };

    const handleModalSave = async () => {
        const updatedCategory = { name: editName, parent_id: editParent || null };
        try {
            const response = await axios.put(`${baseURL}/product_categoriesCRUD/${editId}`, updatedCategory);
            if (response.data.status === 1) {
                fetchCategories();
                setShowModal(false);
                setEditName('');
                setEditParent('');
                setEditId(null);
            } else {
                console.error('Error updating category:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const handleEdit = (category) => {
        setEditName(category.name);
        setEditParent(category.parent_id || '');
        setEditId(category.id);
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`${baseURL}/product_categoriesCRUD/${id}`);
            if (response.data.status === 1) {
                fetchCategories();
            } else {
                console.error('Error deleting category:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    const renderCategories = (categories, parent = null, depth = 0) => {
        return categories
            .filter(category => category.parent_id === parent)
            .map(category => (
                <React.Fragment key={category.id}>
                    <div className={styles.category} style={{ marginLeft: depth * 20 }}>
                        <div>{category.id}</div>
                        <div>{category.name}</div>
                        <div>{category.parent_id}</div>
                        <div>
                            <button className={styles.editButton} onClick={() => handleEdit(category)}>編輯</button>
                            <button className={styles.deleteButton} onClick={() => handleDelete(category.id)}>刪除</button>
                        </div>
                    </div>
                    {renderCategories(categories, category.id, depth + 1)}
                </React.Fragment>
            ));
    };

    const renderParentOptions = (categories, excludeId = null) => {
        return categories
            .filter(category => category.id !== excludeId)
            .map(category => (
                <option key={category.id} value={category.id}>
                    ({category.id}) {category.name}
                </option>
            ));
    };

    return (
        <div className={`container-fluid ${styles.categoryManagementContainer}`}>
            <div className="row">
                <div className="col-md-4">
                    <h2>新增分類</h2>
                    <div className={styles.formGroup}>
                        <label>名稱</label>
                        <input
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label>上層分類</label>
                        <select
                            value={parent}
                            onChange={e => setParent(e.target.value)}
                            className="form-control"
                        >
                            <option value="">無上層分類</option>
                            {renderParentOptions(categories)}
                        </select>
                    </div>
                    <button className={styles.saveButton} onClick={handleSave}>
                        儲存
                    </button>
                </div>
                <div className="col-md-8">
                    <h2>分類列表</h2>
                    <div className={styles.categoryList}>
                        <div className={`${styles.category} ${styles.header}`}>
                            <div>ID</div>
                            <div>名稱</div>
                            <div>上層分類</div>
                            <div>操作</div>
                        </div>
                        {renderCategories(categories)}
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>編輯分類</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>名稱</Form.Label>
                            <Form.Control
                                type="text"
                                value={editName}
                                onChange={e => setEditName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>上層分類</Form.Label>
                            <Form.Control
                                as="select"
                                value={editParent}
                                onChange={e => setEditParent(e.target.value)}
                            >
                                <option value="">無上層分類</option>
                                {renderParentOptions(categories, editId)}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={handleModalSave}>
                        保存變更
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CategoryManagement;
