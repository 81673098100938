import React, { useState, useEffect } from "react";
import axios from "axios";
import baseURL from "./apiConfig";
import { Form, Button, Table, Modal, Container, Row, Col } from "react-bootstrap";

const ExtraCharges = () => {
  const [charges, setCharges] = useState([]);
  const [newCharge, setNewCharge] = useState({ chargename: "", charge: 0, scope: "訂單狀態為待換貨" });
  const [editingCharge, setEditingCharge] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chargeToDelete, setChargeToDelete] = useState(null);

  useEffect(() => {
    fetchCharges();
  }, []);

  const fetchCharges = async () => {
    try {
        const response = await axios.get(`${baseURL}/extraCharges`);
        console.log("Fetched charges:", response.data);
        
        // 確保設置的資料是陣列
        if (Array.isArray(response.data)) {
            setCharges(response.data);
        } else if (response.data.results && Array.isArray(response.data.results)) {
            setCharges(response.data.results);
        } else {
            console.error("Unexpected data format:", response.data);
            setCharges([]); // 如果資料格式不正確，初始化為空陣列
        }
    } catch (error) {
        console.error("Error fetching extra charges:", error);
        setCharges([]); // 在錯誤情況下，將 charges 初始化為空陣列
    }
};



  useEffect(() => {
    console.log("Charges state has been updated:", charges);
  }, [charges]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingCharge) {
      setEditingCharge((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewCharge((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddCharge = async (e) => {
    e.preventDefault();
    try {
      console.log("newCharge before send:", newCharge);
      const response = await axios.post(`${baseURL}/extraCharges`, newCharge);
      console.log("Server response:", response.data);
      if (response.data.status === 1) {
        fetchCharges();
        setNewCharge({ chargename: "", charge: 0, scope: "訂單狀態為待換貨" });
      } else {
        alert(response.data.message || "未接收到正確的訊息");
      }
    } catch (error) {
      console.error("Error adding extra charge:", error);
      alert("Error adding extra charge: " + error.message);
    }
  };

  const handleEditCharge = (charge) => {
    setEditingCharge(charge);
  };

  const handleUpdateCharge = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${baseURL}/extraCharges/${editingCharge.id}`, editingCharge);
      if (response.data.status === 1) {
        fetchCharges();
        setEditingCharge(null);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error updating extra charge:", error);
      alert("Error updating extra charge: " + error.message);
    }
  };

  const handleDeleteCharge = async () => {
    try {
      const response = await axios.delete(`${baseURL}/extraCharges/${chargeToDelete.id}`);
      if (response.data.status === 1) {
        fetchCharges();
        setShowDeleteModal(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting extra charge:", error);
      alert("Error deleting extra charge: " + error.message);
    }
  };

  const handleShowDeleteModal = (charge) => {
    setChargeToDelete(charge);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setChargeToDelete(null);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={4} className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
          <Form onSubmit={editingCharge ? handleUpdateCharge : handleAddCharge}>
            <Form.Group controlId="formChargeName">
              <Form.Label>費用名稱</Form.Label>
              <Form.Control
                type="text"
                name="chargename"
                value={editingCharge ? editingCharge.chargename : newCharge.chargename}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCharge">
              <Form.Label>費用</Form.Label>
              <Form.Control
                type="number"
                name="charge"
                value={editingCharge ? editingCharge.charge : newCharge.charge}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formScope">
              <Form.Label>套用範圍</Form.Label>
              <Form.Control
                as="select"
                name="scope"
                value={editingCharge ? editingCharge.scope : newCharge.scope}
                onChange={handleInputChange}
                required
              >
                <option value="訂單狀態為待換貨">訂單狀態為待換貨</option>
                <option value="其他">其他</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editingCharge ? "更新" : "新增"}
            </Button>
            {editingCharge && (
              <Button
                variant="secondary"
                onClick={() => setEditingCharge(null)}
                className="mt-3 ml-3"
              >
                取消
              </Button>
            )}
          </Form>
        </Col>
        <Col md={8} className="p-4">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>費用名稱</th>
                <th>費用</th>
                <th>套用範圍</th>
                <th>動作</th>
              </tr>
            </thead>
            <tbody>
              {charges.length > 0 ? (
                charges.map((charge, index) => {
                  console.log(`Rendering charge at index ${index}:`, charge);
                  return (
                    <tr key={charge.id}>
                      {/* 如果數據中無需解碼，那麼可以直接顯示 */}
                      <td>{charge.chargename}</td>
                      <td>{charge.charge}</td>
                      <td>{charge.scope}</td>
                      <td>
                        <i
                          className='bx bxs-edit-alt me-2'
                          style={{ fontSize: '25px', cursor: 'pointer' }}
                          onClick={() => handleEditCharge(charge)}
                        ></i>
                        <i
                          className='bx bx-trash'
                          style={{ fontSize: '25px', cursor: 'pointer' }}
                          onClick={() => handleShowDeleteModal(charge)}
                        ></i>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='4' className='text-center'>
                    沒有資料
                  </td>
                </tr>
              )}
            </tbody>

          </Table>
        </Col>
      </Row>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>確認刪除</Modal.Title>
        </Modal.Header>
        <Modal.Body>您確定要刪除這個費用嗎？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            取消
          </Button>
          <Button variant="danger" onClick={handleDeleteCharge}>
            確認
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ExtraCharges;
