import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/ProductDetail.css';
import baseURL from './manager/apiConfig';
import { Carousel, Nav } from 'react-bootstrap';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import YouTube from 'react-youtube';
import QuantityBtn from './QuantityBtn';
import { CartContext } from './CartContext';

const getYouTubeVideoId = (url) => {
  const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regExp);
  return match ? match[1] : null;
};

const ProductDetail = () => {
  const { id } = useParams();
  const { cartItems, setCartItems } = useContext(CartContext);
  const initialProductState = {
    產品特色詳細: {
      text: '',
      youtubeUrl: ''
    },
    產品規格: '',
    左眼度數: '',
    右眼度數: '',
    分類: []
  };

  const [product, setProduct] = useState(initialProductState);
  const [productImages, setProductImages] = useState([]);
  const [featureImages, setFeatureImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [featureImagePreviews, setFeatureImagePreviews] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    fetchProductData();
  }, [id]);

  const fetchProductData = async () => {
    try {
      const response = await axios.get(`${baseURL}/productCRUD/${id}`);
      const productData = response.data;

      const initialProductData = {
        ...productData[0],
        產品特色詳細: {
          text: '',
          youtubeUrl: ''
        },
        散光: productData[0].散光,
        角度: productData[0].角度,
        分類: productData[0].分類 || []
      };

      const storedProductImages = [];
      const storedFeatureImages = [];
      const productImagePreviews = [];
      const featureImagePreviews = [];

      productData.forEach(item => {
        if (item.product_features_text && !initialProductData.產品特色詳細.text) {
          initialProductData.產品特色詳細.text = item.product_features_text;
        }
        if (item.product_features_youtubeUrl && !initialProductData.產品特色詳細.youtubeUrl) {
          initialProductData.產品特色詳細.youtubeUrl = item.product_features_youtubeUrl;
        }
        if (item.product_features_img) {
          storedFeatureImages.push({
            id: item.id,
            path: item.product_features_img,
            isNew: false
          });
          featureImagePreviews.push(item.product_features_img);
        }
        if (item.product_img) {
          storedProductImages.push({
            id: item.id,
            path: item.product_img,
            isMain: item.product_img_main === "1",
            isNew: false
          });
          productImagePreviews.push(item.product_img);
        }
      });

      setProduct(initialProductData);
      setProductImages(storedProductImages);
      setFeatureImages(storedFeatureImages);
      setImagePreviews(productImagePreviews.map(path => process.env.PUBLIC_URL + '/img/' + path));
      setFeatureImagePreviews(featureImagePreviews.map(path => process.env.PUBLIC_URL + '/img/' + path));
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
      setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
      }, 0);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 product-section"> {/* 左边产品展示区 */}
          <SimpleReactLightbox>
            <div style={{ position: "relative", top: "0px" }}>
              <div className="block-inner">
                <div className="ProductDetail-product row">
                  <div className="ProductDetail-product-gallery col-xs-12 col-md-12">
                    <div
                      className="variant-gallery-stage text-center product-image-container"
                      style={{ width: '100%', height: 'auto', minHeight: '300px' }}
                    >
                      <SRLWrapper>
                        <Carousel
                          activeIndex={selectedIndex}
                          onSelect={(selectedIndex) => setSelectedIndex(selectedIndex)}
                          interval={3000}
                          slide={false}
                        >
                          {productImages && productImages.map((product, index) => (
                            product.path && (
                              <Carousel.Item key={index}>
                                <a href={process.env.PUBLIC_URL + '/img/' + product.path}>
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/' + product.path}
                                    alt={`Product Image ${index + 1}`}
                                    style={{
                                      width: '100%',
                                      height: 'auto',
                                      objectFit: 'cover',
                                    }}
                                  />
                                </a>
                              </Carousel.Item>
                            )
                          ))}
                        </Carousel>
                      </SRLWrapper>
                    </div>
                    <div className="thumbnails-container">
                      {productImages && productImages.map((product, index) => (
                        product.path && (
                          <img
                            key={index}
                            src={process.env.PUBLIC_URL + '/img/' + product.path}
                            alt={`Product Thumbnail ${index + 1}`}
                            className="thumbnail"
                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                            onClick={() => setSelectedIndex(index)}
                          />
                        )
                      ))}
                    </div>
                  </div>

                  {product && (
                    <div className="ProductDetail-product-info col-xs-12 col-md-12">
                      <div className="box-default">
                        <h3 className="Product-title mb-3" style={{ textAlign: "left" }}>{product.廠牌}-{product.商品名稱}</h3>
                        {product.注意事項 && (<p className="mb-3" style={{ borderBottom: "1px solid black", paddingBottom: "10px" }}>※{product.注意事項}</p>)}
                        <div className="price-box bg-light mb-4">
                          {product.福利價 !== "0" ? (
                            <div>
                              <span className="discountPrice" style={{ fontWeight: 'bold', color: '#e5721d' }}>NT${product.福利價}</span>
                            </div>
                          ) : (
                            <span className="discountPrice">NT${product.售價}</span>
                          )}
                        </div>
                        <p style={{ whiteSpace: 'pre-wrap' }}>●{product.包片數}</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>●弧度: {product.弧度}</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>●{product.產品特色}</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{product.產品詳述}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SimpleReactLightbox>
        </div>

        <div className="col-md-6 quantity-section"> {/* 右边 QuantityBtn 区域 */}
          <QuantityBtn productInfo={product} sourcePage="ProductDetail" showControls={true}/>
        </div>
      </div>

      <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-12" style={{ paddingRight: '0', paddingLeft: '0' }}>
          <Nav variant="tabs" defaultActiveKey="/home" className="d-flex justify-content-center">
            <Nav.Item>
              <Nav.Link
                onClick={() => handleTabClick(1)}
                className={`Tab-label js-tab-label ${activeTab === 1 ? 'active' : ''}`}>
                商品特色
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => handleTabClick(2)}
                className={`Tab-label js-tab-label ${activeTab === 2 ? 'active' : ''}`}>
                商品規格
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => handleTabClick(3)}
                className={`Tab-label js-tab-label ${activeTab === 3 ? 'active' : ''}`}>
                送貨及付款方式
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="ProductDetail-additionalInfo">
            <section className="Tabset js-tabset" id="UAOFVFNW">
              <div className={`Tab-content js-tab-content ${activeTab === 1 ? 'active' : ''}`} id="tabset-tab-UAOFVFNW-1">
                {activeTab === 1 && (
                  <div className="ProductDetail-description">
                    <div className="ProductDetail-title primary-border-color-after">
                      商品特色
                    </div>
                    <div className="product-features">
                      <p style={{ whiteSpace: 'pre-wrap' }}>{product.產品特色詳細.text}</p>
                      {product.產品特色詳細.youtubeUrl && (
                        <div className="youtube-container">
                          <YouTube videoId={getYouTubeVideoId(product.產品特色詳細.youtubeUrl)} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={`Tab-content js-tab-content ${activeTab === 2 ? 'active' : ''}`}>
                {activeTab === 2 && (
                  <div className="ProductDetail-description">
                    <div className="ProductDetail-title primary-border-color-after">
                      商品規格
                    </div>
                    <p style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: product.產品規格 }}></p>
                  </div>
                )}
              </div>
              <div className={`Tab-content js-tab-content ${activeTab === 3 ? 'active' : ''}`}>
                {activeTab === 3 && (
                  <div className="ProductDetail-description">
                    <div className="ProductDetail-title primary-border-color-after">
                      送貨及付款方式
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
