import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { CartContext } from "./CartContext";
import axios from 'axios';
import baseURL from './manager/apiConfig.js';
import "./css/QuantityBtn.css";

export default function QuantityBtn({ productInfo, sourcePage, numInEachCart, showControls = true }) {
  const username = localStorage.getItem('username');
  const { cartItems, setCartItems } = useContext(CartContext);
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [selectedDegree, setSelectedDegree] = useState('');
  const [selectedLeftEyeDegree, setSelectedLeftEyeDegree] = useState('');
  const [selectedRightEyeDegree, setSelectedRightEyeDegree] = useState('');
  const [selectedAstigmatism, setSelectedAstigmatism] = useState('');
  const [selectedAxis, setSelectedAxis] = useState('');
  const [selectedPresbyopia, setSelectedPresbyopia] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); // 控制成功模态窗口的显示
  const [showModal, setShowModal] = useState(false); // 控制调整数量的模态窗口
  const [discountRate, setDiscountRate] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    if (sourcePage === "Checkout") {
      setCurrentQuantity(numInEachCart);
    }
  }, [numInEachCart, sourcePage]);

  useEffect(() => {
    if (!showSuccessModal) {
      setShowModal(false);
    }
  }, [showSuccessModal]);

  useEffect(() => {
    const fetchDiscount = async () => {
      if (username) {
        try {
          const response = await axios.post(`${baseURL}/discount/getDiscountbyuser`, { username });
          if (response.data.status === 1) {
            const discount = response.data.discountRate;
            setDiscountRate(discount);
          }
        } catch (error) {
          console.error('Error fetching discount:', error);
        }
      }
    };

    fetchDiscount();
  }, [username]);

  useEffect(() => {
    const totalAmount = cartItems.reduce((total, product) => {
      return total += product.福利價 !== "0" ? product.福利價 * product.quantity : product.售價 * product.quantity;
    }, 0);

    setDiscountAmount((totalAmount * discountRate) / 100);
  }, [cartItems, discountRate]);

  const handleChange = (e) => {
    setSelectedDegree(e.target.value); 
    if (productIndexInCart !== -1) { // 如果商品已经在购物车中，更新购物车中的商品度数
      let newCartItems = [...cartItems];
      newCartItems[productIndexInCart].度數 = e.target.value;
      setCartItems(newCartItems);
      localStorage.setItem(`${username}_cartItems`, JSON.stringify(newCartItems));
    }
  };

  let productIndexInCart = cartItems.findIndex((element) => {
    return element.id === productInfo.id && element.度數 === selectedDegree && element.散光 === selectedAstigmatism && element.角度 === selectedAxis && element.老花 === selectedPresbyopia && element.顏色種類 === selectedColor;
  });

  let productIndexInCartwithDegree = cartItems.findIndex((element) => {
    return element.id === productInfo.id && element.度數 === productInfo.度數;
  });

  let [numInCart, setNumInCart] = useState(
    productIndexInCart === -1 ? 0 : cartItems[productIndexInCart].quantity
  );

  const handleAddToCart = () => {
    if ((productInfo.度數 === "" && selectedLeftEyeDegree === "" && selectedRightEyeDegree === "") || selectedAstigmatism === "" || selectedAxis === "" || selectedPresbyopia === "" || selectedColor === "") {
      alert("請選擇所有選項");
      return;
    }

    if (currentQuantity === 0) {
      alert("請選擇數量");
      return;
    }

    let newCartItems;
    const cartItemIndex = cartItems.findIndex((item) => item.id === productInfo.id && item.度數 === selectedDegree && item.左眼度數 === selectedLeftEyeDegree && item.右眼度數 === selectedRightEyeDegree && item.散光 === selectedAstigmatism && item.角度 === selectedAxis && item.老花 === selectedPresbyopia && item.顏色種類 === selectedColor);

    if (cartItemIndex !== -1) {
      // 商品已经在购物车中，增加数量
      const updatedCartItems = [...cartItems];
      updatedCartItems[cartItemIndex].quantity += currentQuantity;
      newCartItems = updatedCartItems;
    } else {
      // 商品不在购物车中，新增到购物车
      const newCartItem = {
        id: productInfo.id,
        商品名稱: productInfo.商品名稱,
        img: productInfo.product_img,
        售價: productInfo.售價,
        福利價: productInfo.福利價,
        產品特色: productInfo.產品特色,
        quantity: currentQuantity,
        度數: selectedDegree,
        左眼度數: selectedLeftEyeDegree,
        右眼度數: selectedRightEyeDegree,
        散光: selectedAstigmatism,
        角度: selectedAxis,
        老花: selectedPresbyopia,
        顏色種類: selectedColor,
        包片數: productInfo.包片數,
        弧度: productInfo.弧度,
        含水量: productInfo.含水量,
        鏡面直徑: productInfo.鏡面直徑,
        著色直徑: productInfo.著色直徑,
      };
      newCartItems = [newCartItem, ...cartItems];
    }

    setCartItems(newCartItems);
    localStorage.setItem(`${username}_cartItems`, JSON.stringify(newCartItems));

    // 关闭选择模态窗口
    setShowModal(false);

    // 清除选择
    setSelectedDegree("");
    setSelectedLeftEyeDegree("");
    setSelectedRightEyeDegree("");
    setSelectedAstigmatism("");
    setSelectedAxis("");
    setSelectedPresbyopia("");
    setSelectedColor("");
    setCurrentQuantity(0);

    // 显示成功模态窗口
    setTimeout(() => {
      setShowSuccessModal(true);
    }, 300); // 等待300ms后显示成功模态窗口
  };

  const handleAdd = () => {
    if (selectedDegree === "" && sourcePage === "ProductList") {
      alert("請選擇度數");
      return;
    }
    let newCartItems;
    if (sourcePage === "Checkout") {
      let newCartArray = [...cartItems];
      newCartArray[productIndexInCartwithDegree].quantity += 1; // 将数量增加1
      newCartItems = newCartArray;
      setCartItems(newCartItems);
      localStorage.setItem(`${username}_cartItems`, JSON.stringify(newCartItems));
      return;
    } 
    setCurrentQuantity(currentQuantity + 1);
  };

  const handleSubtract = () => {
    if (selectedDegree === "" && sourcePage === "ProductList") {
      alert("請選擇度數");
      return;
    }
    if (currentQuantity === 0) {
      return;
    }
    let newCartItems;
    if (sourcePage === "Checkout") {
      let newCartArray = [...cartItems];
      if (newCartArray[productIndexInCartwithDegree].quantity === 1) {
        newCartArray.splice(productIndexInCartwithDegree, 1);
        newCartItems = newCartArray;
        setCartItems(newCartItems);
      } else {
        newCartArray[productIndexInCartwithDegree].quantity -= 1;
        newCartItems = newCartArray;
        setCartItems(newCartItems);
      }
      localStorage.setItem(`${username}_cartItems`, JSON.stringify(newCartItems));
      return;
    }
    setCurrentQuantity(currentQuantity - 1);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDegree("");
    setSelectedLeftEyeDegree("");
    setSelectedRightEyeDegree("");
    setSelectedAstigmatism("");
    setSelectedAxis("");
    setSelectedPresbyopia("");
    setSelectedColor("");
    setCurrentQuantity(0);
  };

  const handleShowModal = () => setShowModal(true);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      {/* ProductList顯示內容  */}
      {sourcePage === "ProductList" && (
        <>
          <Button variant="primary" onClick={handleShowModal}>
            加入購物車
          </Button>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>調整數量</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="addToCart" style={{ padding: "20px", maxWidth: "100%", margin: "auto", borderRadius: "10px",  backgroundColor: "#f9f9f9" }}>
                <div style={{ marginBottom: "15px" }}>
                  {productInfo.度數 && (
                    <>
                      <Form.Label>度數</Form.Label>
                      <select
                        className="mb-5 form-select"
                        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "15px" }}
                        value={selectedDegree}
                        onChange={(e) => setSelectedDegree(e.target.value)}
                      >
                        <option value="">請選擇度數</option>
                        {productInfo.度數.split(",").map((度數, index) => (
                          <option key={index} value={度數}>
                            {度數}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {productInfo.左眼度數 && (
                    <>
                      <Form.Label>左眼度數</Form.Label>
                      <select
                        className="mb-5 form-select"
                        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "15px" }}
                        value={selectedLeftEyeDegree}
                        onChange={(e) => setSelectedLeftEyeDegree(e.target.value)}
                      >
                        <option value="">請選擇左眼度數</option>
                        {productInfo.左眼度數.split(",").map((度數, index) => (
                          <option key={index} value={度數}>
                            {度數}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {productInfo.右眼度數 && (
                    <>
                      <Form.Label>右眼度數</Form.Label>
                      <select
                        className="mb-5 form-select"
                        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "15px" }}
                        value={selectedRightEyeDegree}
                        onChange={(e) => setSelectedRightEyeDegree(e.target.value)}
                      >
                        <option value="">請選擇右眼度數</option>
                        {productInfo.右眼度數.split(",").map((度數, index) => (
                          <option key={index} value={度數}>
                            {度數}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {productInfo.散光 && (
                    <>
                      <Form.Label>散光</Form.Label>
                      <select
                        className="mb-5 form-select"
                        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "15px" }}
                        value={selectedAstigmatism}
                        onChange={(e) => setSelectedAstigmatism(e.target.value)}
                      >
                        <option value="">請選擇散光</option>
                        {productInfo.散光.split(",").map((散光, index) => (
                          <option key={index} value={散光}>
                            {散光}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {productInfo.角度 && (
                    <>
                      <Form.Label>角度</Form.Label>
                      <select
                        className="mb-5 form-select"
                        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "15px" }}
                        value={selectedAxis}
                        onChange={(e) => setSelectedAxis(e.target.value)}
                      >
                        <option value="">請選擇角度</option>
                        {productInfo.角度.split(",").map((角度, index) => (
                          <option key={index} value={角度}>
                            {角度}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {productInfo.老花 && (
                    <>
                      <Form.Label>老花</Form.Label>
                      <select
                        className="mb-5 form-select"
                        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "15px" }}
                        value={selectedPresbyopia}
                        onChange={(e) => setSelectedPresbyopia(e.target.value)}
                      >
                        <option value="">請選擇老花</option>
                        {productInfo.老花.split(",").map((老花, index) => (
                          <option key={index} value={老花}>
                            {老花}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {productInfo.顏色種類 && (
                    <>
                      <Form.Label>顏色種類</Form.Label>
                      <select
                        className="mb-5 form-select"
                        style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "15px" }}
                        value={selectedColor}
                        onChange={(e) => setSelectedColor(e.target.value)}
                      >
                        <option value="">請選擇顏色</option>
                        {productInfo.顏色種類.split(",").map((顏色, index) => (
                          <option key={index} value={顏色}>
                            {顏色}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>

                <div className="input-group mb-3" style={{ display: "flex", alignItems: "center" }}>
                  <button
                    className="subtractBtn"
                    style={{
                      width: "40px",
                      height: "40px",
                      fontWeight: "bold",
                      fontSize: "20px",
                      backgroundColor: "#244e7e",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px 0 0 5px",
                      cursor: "pointer",
                    }}
                    type="button"
                    onClick={handleSubtract}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    className="form-control text-center"
                    style={{ width: "60px", fontWeight: "bold", fontSize: "20px", padding: "5px", border: "1px solid #ccc", borderRadius: "0" }}
                    placeholder=""
                    aria-label="Example text with two button addons"
                    value={currentQuantity}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value);
                      if (!isNaN(newValue)) {
                        setNumInCart(newValue);
                        setCurrentQuantity(newValue);
                      }
                    }}
                  />
                  <button
                    className="addBtn"
                    style={{
                      width: "40px",
                      height: "40px",
                      fontWeight: "bold",
                      fontSize: "20px",
                      backgroundColor: "#244e7e",
                      color: "#fff",
                      border: "none",
                      borderRadius: "0 5px 5px 0",
                      cursor: "pointer",
                    }}
                    type="button"
                    onClick={handleAdd}
                  >
                    +
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleAddToCart}>
                加到購物車
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {/* Checkout顯示內容  */}
      {sourcePage === "Checkout" && (
        <div className="checkoutQuantity">
          <div className="input-group">
            <button className="btn btn-outline-secondary" type="button" onClick={handleSubtract}>-</button>
            <input type="text" className="form-control text-center auto-width-input" placeholder="" aria-label="" value={currentQuantity}
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                if (!isNaN(newValue)) {
                  setNumInCart(newValue);
                  setCurrentQuantity(newValue);
                }
              }} />
            <button className="btn btn-outline-secondary" type="button" onClick={handleAdd}>+</button>
          </div>
        </div>
      )}

      {/* ProductDetail顯示內容  */}
      {sourcePage === "ProductDetail" && (
        <div className="product-detail" style={{ padding: "20px", maxWidth: "600px", margin: "auto", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", backgroundColor: "#f9f9f9" }}>
          
          <div style={{ marginBottom: "15px" }}>
            {productInfo.度數 && (
              <>
                <Form.Label>度數</Form.Label>
                <select
                  className="mb-2 form-select"
                  style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "5px" }}
                  value={selectedDegree}
                  onChange={(e) => setSelectedDegree(e.target.value)}
                >
                  <option value="">請選擇度數</option>
                  {productInfo.度數.split(",").map((度數, index) => (
                    <option key={index} value={度數}>
                      {度數}
                    </option>
                  ))}
                </select>
              </>
            )}

            {productInfo.左眼度數 && (
              <>
                <Form.Label>左眼度數</Form.Label>
                <select
                  className="mb-2 form-select"
                  style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "5px" }}
                  value={selectedLeftEyeDegree}
                  onChange={(e) => setSelectedLeftEyeDegree(e.target.value)}
                >
                  <option value="">請選擇度數</option>
                  {productInfo.左眼度數.split(",").map((度數, index) => (
                    <option key={index} value={度數}>
                      {度數}
                    </option>
                  ))}
                </select>
              </>
            )}

            {productInfo.右眼度數 && (
              <>
                <Form.Label>右眼度數</Form.Label>
                <select
                  className="mb-2 form-select"
                  style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "5px" }}
                  value={selectedRightEyeDegree}
                  onChange={(e) => setSelectedRightEyeDegree(e.target.value)}
                >
                  <option value="">請選擇度數</option>
                  {productInfo.右眼度數.split(",").map((度數, index) => (
                    <option key={index} value={度數}>
                      {度數}
                    </option>
                  ))}
                </select>
              </>
            )}

            {productInfo.散光 && (
              <>
                <Form.Label>散光</Form.Label>
                <select
                  className="mb-2 form-select"
                  style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "5px" }}
                  value={selectedAstigmatism}
                  onChange={(e) => setSelectedAstigmatism(e.target.value)}
                >
                  <option value="">請選擇散光</option>
                  {productInfo.散光.split(",").map((散光, index) => (
                    <option key={index} value={散光}>
                      {散光}
                    </option>
                  ))}
                </select>
              </>
            )}

            {productInfo.角度 && (
              <>
                <Form.Label>角度</Form.Label>
                <select
                  className="mb-2 form-select"
                  style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "5px" }}
                  value={selectedAxis}
                  onChange={(e) => setSelectedAxis(e.target.value)}
                >
                  <option value="">請選擇角度</option>
                  {productInfo.角度.split(",").map((角度, index) => (
                    <option key={index} value={角度}>
                      {角度}
                    </option>
                  ))}
                </select>
              </>
            )}

            {productInfo.老花 && (
              <>
                <Form.Label>老花</Form.Label>
                <select
                  className="mb-2 form-select"
                  style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "5px" }}
                  value={selectedPresbyopia}
                  onChange={(e) => setSelectedPresbyopia(e.target.value)}
                >
                  <option value="">請選擇老花</option>
                  {productInfo.老花.split(",").map((老花, index) => (
                    <option key={index} value={老花}>
                      {老花}
                    </option>
                  ))}
                </select>
              </>
            )}

            {productInfo.顏色種類 && (
              <>
                <Form.Label>顏色種類</Form.Label>
                <select
                  className="mb-2 form-select"
                  style={{ padding: "10px", fontSize: "16px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "5px" }}
                  value={selectedColor}
                  onChange={(e) => setSelectedColor(e.target.value)}
                >
                  <option value="">請選擇顏色</option>
                  {productInfo.顏色種類.split(",").map((顏色, index) => (
                    <option key={index} value={顏色}>
                      {顏色}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>

          {showControls && (<div className="input-group mb-3" style={{ display: "flex", alignItems: "center" }}>
            <button
              className="subtractBtn"
              style={{
                width: "40px",
                height: "40px",
                fontWeight: "bold",
                fontSize: "20px",
                backgroundColor: "#244e7e",
                color: "#fff",
                border: "none",
                borderRadius: "5px 0 0 5px",
                cursor: "pointer",
              }}
              type="button"
              onClick={handleSubtract}
            >
              -
            </button>
            <input
              type="text"
              className="form-control text-center"
              style={{ width: "60px", fontWeight: "bold", fontSize: "20px", padding: "5px", border: "1px solid #ccc", borderRadius: "0" }}
              placeholder=""
              aria-label="Example text with two button addons"
              value={currentQuantity}
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                if (!isNaN(newValue)) {
                  setNumInCart(newValue);
                  setCurrentQuantity(newValue);
                }
              }}
            />
            <button
              className="addBtn"
              style={{
                width: "40px",
                height: "40px",
                fontWeight: "bold",
                fontSize: "20px",
                backgroundColor: "#244e7e",
                color: "#fff",
                border: "none",
                borderRadius: "0 5px 5px 0",
                cursor: "pointer",
              }}
              type="button"
              onClick={handleAdd}
            >
              +
            </button>
          </div>)}
          
          {showControls && (
            <Button variant="primary" onClick={handleAddToCart} style={{ marginTop: "20px" }}>
              加到購物車
            </Button>
          )}
        </div>
      )}

      {/* 加入成功模态窗口 */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>成功</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          商品已成功加入購物車！
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
