import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { CartContext } from './CartContext';
import Checkout from './Checkout';
import CheckShippingInfo from './CheckShippingInfo';
import ProductList from './ProductList';
import ListUser from './manager/ListUser';
import CheckOrders from './CheckOrders';
import CreateProduct from './manager/CreateProduct';
import ModifyProduct from './manager/ModifyProduct';
import ListProducts from './manager/ListProducts';
import Setting from './manager/SystermSetting';
import ManagerOrders from './manager/ManagerOrders';
import ProductManagement from './manager/ProductManagement';
import DiscountSettings from './manager/setting-discountSettings';
import UserProfile from './UserProfile';
import Logout from './logout';
import Login from './Login';
import ProductDetail from './ProductDetail';
import NotFound from './NotFound';
import Footer from './footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/app.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import baseURL from './manager/apiConfig';

const App = () => {
  const [cartItems, setCartItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const username = localStorage.getItem('username');
  const userPermission = localStorage.getItem('perm');

  useEffect(() => {
    const savedCartItems = localStorage.getItem(`${username}_cartItems`);
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }

    // Fetch brands and categories
    axios.get(`${baseURL}/productShowByCategory/brands`)
      .then(response => setBrands(response.data))
      .catch(error => console.error('Error fetching brands:', error));

    axios.get(`${baseURL}/productShowByCategory/categories`)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, [username]);

  const handleLogout = () => {
    localStorage.removeItem(`${username}_cartItems`);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
    setCartItems([]);
    window.location.reload();
  };

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  return (
    <BrowserRouter>
      <CartContext.Provider value={{ cartItems, setCartItems }}>
        <Navbar expand="sm" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="/">E世代</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="各大品牌" id="brands-nav-dropdown">
                  {Array.isArray(brands) && brands.map((brand, index) => (
                    <NavDropdown.Item key={index} as={Link} to={`/products/brand/${brand.廠牌}`}>
                      {brand.廠牌}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                {Array.isArray(categories) && categories.filter(cat => cat.parent_id === null).map((mainCat, index) => (
                  <NavDropdown key={index} title={mainCat.name} id={`category-nav-dropdown-${mainCat.id}`}>
                    {categories.filter(cat => cat.parent_id === mainCat.id).map((subCat, subIndex) => (
                      <NavDropdown.Item key={subIndex} as={Link} to={`/products/category/${subCat.id}`}>
                        {subCat.name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ))}
              </Nav>
              <Nav className="ms-auto">
                {isLoggedIn && userPermission !== '0' && (
                  <NavDropdown title={username} id="basic-nav-dropdown" className="custom-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/UserProfile">個人資訊</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/CheckOrders">我的訂單</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout}>登出</NavDropdown.Item>
                  </NavDropdown>
                )}
                {userPermission === '0' && (
                  <NavDropdown title={username + " 管理員帳號"} id="basic-nav-dropdown" className="custom-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/manager/ProductManagement">產品管理</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/manager/ManagerOrders">訂單管理</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/manager/Setting">系統設定</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout}>登出</NavDropdown.Item>
                  </NavDropdown>
                )}
                
                {!isLoggedIn && (
                  <Nav.Link as={Link} to="/login">登入</Nav.Link>
                )}
                <form className="d-flex">
                  <Link className="nav-item nav-link" to="/checkout">購物車 <i className="bx bxs-cart bx-sm"></i><span className="position-absolute top-30 start-80 translate-middle badge rounded-pill bg-danger">{cartItems.reduce((sum, item) => sum + item.quantity, 0)}</span></Link>
                </form>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/CheckOrders" element={<CheckOrders />} />
          <Route path="/CheckShippingInfo" element={<CheckShippingInfo />} />
          <Route path="/products/brand/:brand" element={<ProductList />} />
          <Route path="/products/category/:category" element={<ProductList />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/UserProfile" element={<UserProfile />} />
          {userPermission === '0' && (
           <>
              <Route path="/manager/ProductManagement" element={<ProductManagement />}>
                  <Route index element={<ListProducts />} />
                  <Route path="ListProducts" element={<ListProducts />} />
              </Route>
              <Route path="/manager/ModifyProduct/:id" element={<ModifyProduct />} />
          </>
          )}
          {userPermission === '0' && (
              <>
                  <Route path="/manager/Setting" element={<Setting />}>
                      <Route path="discountSettings" element={<DiscountSettings />} />
                  </Route>
              </>
          )}
          {userPermission === '0' && (<Route path="/manager/Setting" element={<Setting />} />)}
          {userPermission === '0' && (<Route path="/manager/ListUser" element={<ListUser />} />)}
          {/* {userPermission === '0' && (<Route path="/manager/CreateProduct" element={<CreateProduct />} />)} */}
          {userPermission === '0' && (<Route path="/manager/ManagerOrders" element={<ManagerOrders />} />)}
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </CartContext.Provider>
    </BrowserRouter>
  );
};

export default App;
