import React, { useState, useEffect } from "react";
import axios from "axios";
import baseURL from "./apiConfig";
import { Form, Button, Table, Modal, Container, Row, Col } from "react-bootstrap";

const ShippingCostSettings = () => {
  const [settings, setSettings] = useState([]);
  const [newSetting, setNewSetting] = useState({ shipping_name: "", shipping_cost_start: 0, shipping_cost_end: 100000, shipping_cost: "" });
  const [editingSetting, setEditingSetting] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [settingToDelete, setSettingToDelete] = useState(null);

  useEffect(() => {
    fetchShippingCostSettings();
  }, []);

  const fetchShippingCostSettings = async () => {
    try {
      const response = await axios.get(`${baseURL}/shippingCostSettings`);
      setSettings(response.data);
    } catch (error) {
      console.error("Error fetching shipping cost settings:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingSetting) {
      // 運費名稱為特定值時禁止修改
      if (name === 'shipping_name' && ['基本運費', '免運費', '離島運費'].includes(editingSetting.shipping_name)) {
        return;
      }
      setEditingSetting((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewSetting((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddSetting = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/shippingCostSettings`, newSetting);
      console.log("Add response:", response.data);
      if (response.data.status === 1) {
        fetchShippingCostSettings();
        setNewSetting({ shipping_name: "", shipping_cost_start: 0, shipping_cost_end: 100000, shipping_cost: "" });
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error adding shipping cost setting:", error);
      alert("Error adding shipping cost setting: " + error.message);
    }
  };

  const handleEditSetting = (setting) => {
    setEditingSetting(setting);
  };

  const handleUpdateSetting = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${baseURL}/shippingCostSettings/${editingSetting.id}`, editingSetting);
      console.log("Update response:", response.data);
      if (response.data.status === 1) {
        fetchShippingCostSettings();
        setEditingSetting(null);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error updating shipping cost setting:", error);
      alert("Error updating shipping cost setting: " + error.message);
    }
  };

  const handleDeleteSetting = async () => {
    try {
      const response = await axios.delete(`${baseURL}/shippingCostSettings/${settingToDelete.id}`);
      console.log("Delete response:", response.data);
      if (response.data.status === 1) {
        fetchShippingCostSettings();
        setShowDeleteModal(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting shipping cost setting:", error);
      alert("Error deleting shipping cost setting: " + error.message);
    }
  };

  const handleShowDeleteModal = (setting) => {
    setSettingToDelete(setting);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSettingToDelete(null);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={4} className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
          <Form onSubmit={editingSetting ? handleUpdateSetting : handleAddSetting}>
            <Form.Group controlId="formShippingName">
              <Form.Label>運費名稱</Form.Label>
              <Form.Control
                type="text"
                name="shipping_name"
                value={editingSetting ? editingSetting.shipping_name : newSetting.shipping_name}
                onChange={handleInputChange}
                maxLength="50"
                required
                disabled={editingSetting && ['基本運費', '免運費', '離島運費'].includes(editingSetting.shipping_name)}
              />
            </Form.Group>
            <Form.Group controlId="formShippingCostStart">
              <Form.Label>運費金額範圍開始</Form.Label>
              <Form.Control
                type="number"
                name="shipping_cost_start"
                min="0"
                max="100000"
                value={editingSetting ? editingSetting.shipping_cost_start : newSetting.shipping_cost_start}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formShippingCostEnd">
              <Form.Label>運費金額範圍結束</Form.Label>
              <Form.Control
                type="number"
                name="shipping_cost_end"
                min="0"
                max="100000"
                value={editingSetting ? editingSetting.shipping_cost_end : newSetting.shipping_cost_end}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formShippingCost">
              <Form.Label>運費金額</Form.Label>
              <Form.Control
                type="number"
                name="shipping_cost"
                min="0"
                max="100000"
                value={editingSetting ? editingSetting.shipping_cost : newSetting.shipping_cost}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editingSetting ? "更新" : "新增"}
            </Button>
            {editingSetting && (
              <Button
                variant="secondary"
                onClick={() => setEditingSetting(null)}
                className="mt-3 ml-3"
              >
                取消
              </Button>
            )}
          </Form>
        </Col>
        <Col md={8} className="p-4">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>運費名稱</th>
                <th>運費金額範圍</th>
                <th>運費金額</th>
                <th>動作</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((setting) => (
                <tr key={setting.id}>
                  <td>{setting.shipping_name}</td>
                  <td>{`${setting.shipping_cost_start} - ${setting.shipping_cost_end}`}</td>
                  <td>{setting.shipping_cost}</td>
                  <td>
                    <i className='bx bxs-edit-alt me-2' style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => handleEditSetting(setting)}></i>
                    {setting.shipping_name !== "基本運費" && setting.shipping_name !== "免運費" && setting.shipping_name !== "離島運費" && (
                      <i className='bx bx-trash' style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => handleShowDeleteModal(setting)}></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-4">
            <p><strong>基本運費 & 免運費為每筆訂單運費計算規則</strong></p>
            <p>當送貨地址符合離島條件(地址內包含金門縣/連江縣/澎湖縣時,系統自動加上離島運費)</p>
          </div>
        </Col>
      </Row>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>確認刪除</Modal.Title>
        </Modal.Header>
        <Modal.Body>您確定要刪除這個運費設定嗎？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            取消
          </Button>
          <Button variant="danger" onClick={handleDeleteSetting}>
            確認
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ShippingCostSettings;
