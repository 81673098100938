import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import baseURL from './manager/apiConfig';

const OrderMessage = ({ orderId, show, handleClose }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isManager, setIsManager] = useState(false);

    useEffect(() => {
        const perm = localStorage.getItem('perm');
        setIsManager(perm === '0');
    }, []);

    useEffect(() => {
        if (show) {
            fetchMessages();
        }
    }, [show]);

    const fetchMessages = async () => {
        try {
            const response = await axios.get(`${baseURL}/OrderMessage/`, {
                params: { orderId }
            });
            setMessages(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleSendMessage = async () => {
        try {
            const username = isManager ? '管理者' : localStorage.getItem('username');
            const response = await axios.post(`${baseURL}/OrderMessage`, {
                orderId,
                username,
                message: newMessage
            });
            if (response.status === 200) {
                setNewMessage('');
                fetchMessages();
            } else {
                console.error('Failed to send message:', response);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        return date.toLocaleString();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>訂單留言</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {messages.length > 0 ? (
                    messages.map((msg, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: msg.username === '管理者' ? 'space-between' : 'flex-start', marginBottom: '10px' }}>
                            {msg.username === '管理者' ? (
                                <>
                                    <div style={{ whiteSpace: 'nowrap', marginRight: '10px', fontSize: '12px', color: '#888' }}>
                                        <small>{formatTimestamp(msg.created_at)}</small>
                                    </div>
                                    <div style={{ flex: 1, textAlign: 'right' }}>
                                        <strong>{msg.username}</strong>: {msg.message}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ flex: 1, textAlign: 'left' }}>
                                        <strong>{msg.username}</strong>: {msg.message}
                                    </div>
                                    <div style={{ whiteSpace: 'nowrap', marginLeft: '10px', fontSize: '12px', color: '#888' }}>
                                        <small>{formatTimestamp(msg.created_at)}</small>
                                    </div>
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    <p>目前沒有留言</p>
                )}
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="輸入留言..."
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    關閉
                </Button>
                <Button variant="primary" onClick={handleSendMessage}>
                    送出留言
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderMessage;
