import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import QuantityBtn from './QuantityBtn';
import ItemDelBtn from './ItemDelBtn';
import { CartContext } from './CartContext';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Stack from 'react-bootstrap/Stack';
import axios from 'axios';
import baseURL from './manager/apiConfig.js';

export default function Checkout() {
    let { cartItems } = useContext(CartContext);
    let cartEmpty = cartItems.length <= 0;
    let navigate = useNavigate();

    let grandTotal = cartItems.reduce((total, product) => {
        total += product.福利價 !== "0" ? product.福利價 * product.quantity : product.售價 * product.quantity;
        return total;
    }, 0);

    const freeShippingPrice = 5999;
    const [discounts, setDiscounts] = useState([]);
    const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
    const [discountedTotal, setDiscountedTotal] = useState(grandTotal);

    useEffect(() => {
        let isMounted = true; // 追踪组件是否已挂载

        const fetchDiscounts = async () => {
            const username = localStorage.getItem('username');
            if (username) {
                try {
                    const response = await axios.post(`${baseURL}/discount/getDiscountbyuser`, { username });
                    if (response.data.status === 1 && isMounted) {
                        const discounts = response.data.discounts;
                        setDiscounts(discounts);
                        calculateTotalDiscount(discounts);
                    }
                } catch (error) {
                    if (isMounted) {
                        console.error('Error fetching discounts:', error);
                    }
                }
            }
        };

        fetchDiscounts();

        return () => {
            isMounted = false; // 在组件卸载时将标志设置为false
        };
    }, [grandTotal]);

    const calculateTotalDiscount = (discounts) => {
        let runningTotal = grandTotal;
        let totalDiscount = 0;
    
        discounts.forEach(discount => {
            if (discount.discount_rate) {
                let discountAmount = (runningTotal * discount.discount_rate) / 100;
                totalDiscount -= discountAmount;
                runningTotal -= discountAmount; // 更新折扣后的总额
            } else if (discount.discount_amount) {
                totalDiscount -= discount.discount_amount;
                runningTotal -= discount.discount_amount; // 更新折扣后的总额
            }
        });

        // 取絕對值，以確保顯示正確的總折扣金額
        let absTotalDiscount = Math.abs(totalDiscount);
    
        setTotalDiscountAmount(absTotalDiscount);
        setDiscountedTotal(runningTotal); // 更新折扣后的总额
    };

    const handleCheckout = () => {
        navigate('/checkShippingInfo');
    };

    return (
        <>
            <header className="bg-success py-5 mb-5">
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder" style={{ color: 'white' }}>購物車</h1>
                        <ProgressBar variant="warning" now={44} />
                        <Stack direction="horizontal" gap={3}>
                            <div className="p-2">1.確認商品</div>
                            <div className="vr" />
                            <div
                                className="p-2 ms-auto"
                                onClick={handleCheckout}
                                style={{ cursor: 'pointer', color: 'white' }}
                                onMouseEnter={(e) => e.target.style.color = 'yellow'}
                                onMouseLeave={(e) => e.target.style.color = 'white'}
                            >
                                2.確認送貨資訊
                            </div>
                        </Stack>
                    </div>
                </div>
            </header>
            <div className='mb-5' style={{ position: "relative", top: "20px" }}>
                {
                    cartEmpty &&
                    <div>
                        <div className="nothingInCart">購物車現在沒有商品<br /><br />
                            <Link to="/">去產品頁看看吧</Link></div>
                    </div>
                }

                {
                    !cartEmpty &&
                    <div className="container">
                        <div className="cartSection">
                            <table className="table table-secondary table-striped align-middle align-items-center">
                                <tbody>
                                    {
                                        cartItems.map((product, index) => (
                                            <tr key={`${product.id}-${index}`}>
                                                <td>
                                                    <Link to={'/product/' + product.id}>
                                                        <img style={{ maxWidth: "100px" }} src={process.env.PUBLIC_URL + '/img/' + product.img} alt={product.商品名稱} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    {product.商品名稱}<br />
                                                    <span style={{ color: 'blue' }}>{product.包片數}裝</span><br />
                                                    {product.度數 && <>度數: <span style={{ color: 'blue' }}>{product.度數}</span></>}{product.度數 && <br />}
                                                    {product.左眼度數 && <>左眼度數: <span style={{ color: 'blue' }}>{product.左眼度數}</span></>}{product.左眼度數 && <br />}
                                                    {product.右眼度數 && <>右眼度數: <span style={{ color: 'blue' }}>{product.右眼度數}</span></>}{product.右眼度數 && <br />}
                                                    {product.散光 && <>散光: <span style={{ color: 'blue' }}>{product.散光}</span></>}{product.散光 && <br />}
                                                    {product.角度 && <>角度: <span style={{ color: 'blue' }}>{product.角度}</span></>}{product.角度 && <br />}
                                                    {product.老花 && <>老花: <span style={{ color: 'blue' }}>{product.老花}</span></>}{product.老花 && <br />}
                                                    {product.顏色種類 && <>顏色: <span style={{ color: 'blue' }}>{product.顏色種類}</span></>}
                                                </td>
                                                <td width="120">
                                                    <QuantityBtn key={product.id + product.degree} productInfo={product} sourcePage="Checkout" numInEachCart={product.quantity} />
                                                </td>
                                                <td>
                                                    <div className="productSubTotal">
                                                        <p>{product.福利價 !== "0" ? product.福利價 : product.售價}元</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="productSubTotal">
                                                        {product.福利價 !== "0" ? product.福利價 * product.quantity : product.售價 * product.quantity}元
                                                    </div>
                                                </td>
                                                <td>
                                                    <ItemDelBtn productInfo={product} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="checkoutSection">
                            <div>全部貨品總共</div>
                            <div className="grandTotal">{grandTotal.toFixed(2)}元</div>
                            {/* <div>
                                {discounts.length > 0 && (
                                    <>
                                        {discounts.map((discount, index) => (
                                            <div key={index}>
                                                {discount.discount_rate ? (
                                                    <div>折扣{index + 1} ({discount.discount_name}): {discount.discount_rate}%</div>
                                                ) : (
                                                    <div>折扣{index + 1} ({discount.discount_name}): {discount.discount_amount}元</div>
                                                )}
                                            </div>
                                        ))}
                                        <div>總折扣: {Math.round(totalDiscountAmount)}元</div>
                                        <div>折扣後總金額: {discountedTotal.toFixed(0)}元</div>
                                    </>
                                )}
                            </div> */}
                            
                            {/* {
                                discountedTotal >= freeShippingPrice ?
                                    <div className="freeShipping">✔️我們免費送貨</div> :
                                    <div className="noShipping">滿${freeShippingPrice}免費送貨<br />還差${(freeShippingPrice - discountedTotal).toFixed(0)}</div>
                            } */}
                            <button onClick={handleCheckout}>確認送貨資訊</button>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
