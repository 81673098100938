import axios from "axios"
import { useEffect, useState } from "react";
import { Link, Routes, Route } from "react-router-dom"; 
import baseURL from "./apiConfig.js";
import CreateUser from './CreateUser';
import EditUser from './EditUser';

export default function ListUser() {

    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get(`${baseURL}/userCRUD/`).then(function(response) {
            console.log(response.data);
            //解析 response 
            setUsers(response.data);
        });
    }

    const deleteUser = (id) => {
        axios.delete(`${baseURL}/userCRUD/${id}/delete`).then(function(response){
            console.log(response.data);
            getUsers();
        });
    }
    return (
        <div className="App">
            <h1>List Users</h1>
            <div>
                <Link to="/manager/CreateUser">Create User</Link>
            </div>
            <Routes>
                <Route index element={<div>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(users) && users.map((user, key) =>
                                <tr key={key}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.mobile}</td>
                                    <td>
                                        <Link to={`/manager/EditUser/${user.id}`} style={{marginRight: "10px"}}>Edit</Link>
                                        <button onClick={() => deleteUser(user.id)}>Delete</button>
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>} />
                <Route path="user/create" element={<CreateUser />} />
                <Route path="user/:id/edit" element={<EditUser />} />
            </Routes>
        </div>
    )
}
