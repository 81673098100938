import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import QuantityBtn from "./QuantityBtn";
import axios from "axios";
import baseURL from "./manager/apiConfig.js";
import ReactPaginate from 'react-paginate';
import "./css/ProductList.css";

export default function ProductList() {
  let [productList, setProductList] = useState([]);
  let [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 24;
  const productsPerRow = 4;

  const { brand, category } = useParams();

  useEffect(() => {
    const getProductList = async () => {
      try {
        let response;
        if (brand) {
          response = await axios.get(`${baseURL}/productShowByCategory/brand/${brand}`);
        } else if (category) {
          response = await axios.get(`${baseURL}/productShowByCategory/category/${category}`);
        } else {
          response = await axios.get(`${baseURL}/productCRUD/getall`);
        }
        setProductList(response.data);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    getProductList();
  }, [brand, category]);

  const filteredProducts = productList.filter(product =>
    product.商品名稱.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    product.顏色種類.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    product.包片數.toString().toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredProducts.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div>
      <header className="bg-success py-5 mb-5">
        <div className="container px-4 px-lg-5 my-5">
          <div className="text-center text-white">
            <h1 className="display-4 fw-bolder" style={{ color: 'white' }}>E世代</h1>
            <div className="p-2">線上購物系統</div>
          </div>
        </div>
      </header>

      <div className="navbar-nav align-items-center">
        <span className="border border-1">
          <div className="nav-item d-flex align-items-center ms-4 mt-1 mb-1">
            <i className="bx bx-search fs-4 lh-0"></i>
            <input
              type="text"
              className="form-control border-0 shadow-none"
              placeholder="輸入關鍵字搜尋產品..."
              aria-label="輸入關鍵字搜尋產品..."
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </span>
      </div>

      <div className="container">
        {currentPageData.map((product, index) => (
          <div className="containerItem p-3 shadow-sm" key={product.id} style={{ position: 'relative' }}>
            {product.福利價 != 0 && (
              <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'red',
                color: 'white',
                padding: '5px',
                borderRadius: '5px',
                fontSize: '12px'
              }}>
                ON SALE
              </div>
            )}
            <Link to={"/product/" + product.id}>
              <img
                src={process.env.PUBLIC_URL + "/img/" + product.product_img}
                alt={product.商品名稱}
              />
            </Link>

            <div className="productName">
              <figure className="text-center">
                <blockquote className="blockquote">
                  {product.商品名稱}
                </blockquote>
                <figcaption className="blockquote-footer">
                  【{product.顏色種類}】({product.包片數}裝)
                </figcaption>
              </figure>
            </div>

            {product.福利價 != 0 ? (
              <div className="producPrice">
                <span className="discountPrice" style={{ fontWeight: 'bold', color: '#e5721d' }}>NT${product.福利價}</span>
              </div>
            ) : (
              <div className="producPrice">NT${product.售價}</div>
            )}
            <QuantityBtn productInfo={product} sourcePage="ProductList" />

            {(index + 1) % productsPerRow === 0 && index !== currentPageData.length - 1 &&
              <div className="clearfix"></div>
            }
          </div>
        ))}
      </div>

      <ReactPaginate
        previousLabel={<i className='bx bx-chevron-left'></i>}
        nextLabel={<i className='bx bx-chevron-right'></i>}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}
