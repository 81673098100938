// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './css/NotFound.css'; // 為 NotFound 組件添加樣式

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">找不到頁面</p>
      <Link to="/" className="not-found-link">返回首頁</Link>
    </div>
  );
}

export default NotFound;
