// Footer.js
import React from 'react';
import './css/footer.css'; // 引入CSS文件
import lineQRCode from './images/line-qrcode.png'; // 確保圖片路徑正確

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-3">
            <h5>訂購常見問題</h5>
            <p>商品配送|取消訂單|商品退換貨</p>
          </div>
          <div className="col-md-4">
            <h5>聯繫我們</h5>
            <ul className="list-unstyled">
              <li>0970-205-332</li>
              <li>easyday0529@yahoo.com.tw</li>
              <li>新北市土城區裕民路80號</li>
            </ul>
          </div>
          <div className="col-md-2">
            <h5>關注我們</h5>
            <div className="social-icons">
              <a href="#"><i className="bx bxl-facebook"></i></a>
              <a href="#"><i className="bx bxl-twitter"></i></a>
              <a href="#"><i className="bx bxl-instagram"></i></a>
            </div>
          </div>
          <div className="col-md-2">
            <h5>加入我們</h5>
            <img src={lineQRCode} alt="Line QR Code" className="line-qrcode img-fluid" />
          </div>
        </div>
      </div>
      <div className="row">
          <div className="col-12 text-center mt-3">
            <span className="text-muted">© 2024 E世代. All rights reserved.</span>
          </div>
        </div>
    </footer>
  );
}

export default Footer;
