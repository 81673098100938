import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Login from './Login'; // 假設有一個 Login 組件用於登入
import { Helmet } from 'react-helmet'; // 引入 Helmet 組件

// 從本地存儲中讀取初始登入狀態
const initialLoggedInState = localStorage.getItem('isLoggedIn') === 'true';

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
     
    </Helmet>
    {initialLoggedInState ? (
      <App handleLogout={() => { localStorage.setItem('isLoggedIn', 'false'); }} />
    ) : (
      <Login onLogin={() => { localStorage.setItem('isLoggedIn', 'true'); }} />
    )}
  </React.StrictMode>,
  document.getElementById('root')
);
