import React, { createContext, useState, useContext } from 'react';

// 創建Context
const ProductManagementContext = createContext();

// 創建Provider組件
export const ProductManagementProvider = ({ children }) => {
    const [activeSection, setActiveSection] = useState('list-products');
    const [selectedProductId, setSelectedProductId] = useState(null);

    const handleMenuClick = (section, id = null) => {
        setActiveSection(section);
        if (id !== null) {
            setSelectedProductId(id);
        }
    };

    return (
        <ProductManagementContext.Provider value={{ activeSection, selectedProductId, handleMenuClick }}>
            {children}
        </ProductManagementContext.Provider>
    );
};

// 創建一個自定義Hook來簡化Context的使用
export const useProductManagement = () => useContext(ProductManagementContext);
