import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from './apiConfig.js';

const SettingOthers = () => {
    const [remittances, setRemittances] = useState([]);
    const [form, setForm] = useState({
        id: null,
        seller_id: '',
        bank_name: '',
        bank_code: '',
        account_name: '',
        account_number: ''
    });

    useEffect(() => {
        fetchRemittances();
    }, []);

    const fetchRemittances = async () => {
        try {
            const response = await axios.get(`${baseURL}/set_RemittanceManager`);
            setRemittances(response.data);
        } catch (error) {
            console.error('Error fetching remittances:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseURL}/set_RemittanceManager`, form);
            if (response.data.status === 1) {
                alert('更新完畢');
                fetchRemittances();
                setForm({
                    id: null,
                    seller_id: '',
                    bank_name: '',
                    bank_code: '',
                    account_name: '',
                    account_number: ''
                });
            } else {
                alert('發生錯誤，請稍後再試');
            }
        } catch (error) {
            console.error('Error saving remittance info:', error);
        }
    };

    const handleEdit = (remittance) => {
        setForm(remittance);
    };

    const handleDelete = async (id) => {
        if (window.confirm('您確定要刪除此筆資料?')) {
            try {
                const response = await axios.delete(`${baseURL}/set_RemittanceManager/${id}`);
                if (response.data.status === 1) {
                    alert('更新完畢');
                    fetchRemittances();
                } else {
                    alert('發生錯誤，請稍後再試');
                }
            } catch (error) {
                console.error('Error deleting remittance info:', error);
            }
        }
    };

    return (
        <div className="container-fluid mb-5">
            <div className="d-flex justify-content-center">
                <div className="row w-100">
                    <div className="col-md-4">
                        <h2>匯款帳戶設定</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>用戶登入帳號 (填0 = 全系統通用 , 填帳號 = 該帳號顯示此設定) <span className="text-danger">*</span></label>
                                <input type="text" name="seller_id" value={form.seller_id} onChange={handleChange} required className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>銀行名稱 <span className="text-danger">*</span></label>
                                <input type="text" name="bank_name" value={form.bank_name} onChange={handleChange} required className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>銀行代號 <span className="text-danger">*</span></label>
                                <input type="text" name="bank_code" value={form.bank_code} onChange={handleChange} required minLength="3" maxLength="3" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>戶名 <span className="text-danger">*</span></label>
                                <input type="text" name="account_name" value={form.account_name} onChange={handleChange} required className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>帳號 <span className="text-danger">*</span></label>
                                <input type="text" name="account_number" value={form.account_number} onChange={handleChange} required minLength="10" maxLength="14" className="form-control" />
                            </div>
                            <button type="submit" className="btn btn-primary mt-3">儲存</button>
                            {form.id && (
                                <button type="button" className="btn btn-secondary mt-3 ml-3" onClick={() => setForm({
                                    id: null,
                                    seller_id: '',
                                    bank_name: '',
                                    bank_code: '',
                                    account_name: '',
                                    account_number: ''
                                })}>取消</button>
                            )}
                        </form>
                    </div>
                    <div className="col-md-6">
                        <h3>銀行帳戶資訊</h3>
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>帳戶ID</th>
                                    <th>銀行名稱</th>
                                    <th>銀行代號</th>
                                    <th>戶名</th>
                                    <th>帳號</th>
                                    <th>動作</th>
                                </tr>
                            </thead>
                            <tbody>
                                {remittances.map((remittance) => (
                                    <tr key={remittance.id}>
                                        <td>{remittance.id}</td>
                                        <td>{remittance.seller_id}</td>
                                        <td>{remittance.bank_name}</td>
                                        <td>{remittance.bank_code}</td>
                                        <td>{remittance.account_name}</td>
                                        <td>{remittance.account_number}</td>
                                        <td>
                                            <i className='bx bxs-edit-alt me-2' style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => handleEdit(remittance)}></i>
                                            <i className='bx bx-trash' style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => handleDelete(remittance.id)}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingOthers;
