import React, { useState, useEffect } from "react";
import axios from "axios";
import baseURL from "./apiConfig";
import { Form, Button, Table, Modal, Container, Row, Col } from "react-bootstrap";

const DiscountSettings = () => {
  const [users, setUsers] = useState([]);
  const [discountSettings, setDiscountSettings] = useState([]);
  const [newSetting, setNewSetting] = useState({ username: "", discount_name: "", discount_rate: "", discount_amount: "" });
  const [editingSetting, setEditingSetting] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [settingToDelete, setSettingToDelete] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    fetchUsers();
    fetchDiscountSettings();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${baseURL}/discountSettings/users`);
      setUsers(response.data);
      console.log("Users",users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchDiscountSettings = async () => {
    try {
      const response = await axios.get(`${baseURL}/discountSettings`);
      setDiscountSettings(response.data);
    } catch (error) {
      console.error("Error fetching discount settings:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSetting((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddSetting = async (e) => {
    e.preventDefault();
    if ((newSetting.discount_rate && newSetting.discount_amount) || (!newSetting.discount_rate && !newSetting.discount_amount)) {
      alert("Please enter either discount rate or discount amount, but not both.");
      return;
    }
    try {
      const response = await axios.post(`${baseURL}/discountSettings`, newSetting);
      console.log("Add response:", response.data); // Add log here
      if (response.data.status === 1) {
        fetchDiscountSettings();
        setNewSetting({ username: "", discount_name: "", discount_rate: "", discount_amount: "" });
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error adding discount setting:", error);
      alert("Error adding discount setting: " + error.message);
    }
  };

  const handleEditSetting = (setting) => {
    // console.log("Edit setting:", setting); // Add log here
    setEditingSetting(setting);
  };

  const handleUpdateSetting = async (e) => {
    e.preventDefault();
    if ((editingSetting.discount_rate && editingSetting.discount_amount) || (!editingSetting.discount_rate && !editingSetting.discount_amount)) {
      alert("Please enter either discount rate or discount amount, but not both.");
      return;
    }
    try {
      const response = await axios.put(`${baseURL}/discountSettings/${editingSetting.username}`, editingSetting);
      console.log("Update response:", response.data); // Add log here
      if (response.data.status === 1) {
        fetchDiscountSettings();
        setEditingSetting(null);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error updating discount setting:", error);
      alert("Error updating discount setting: " + error.message);
    }
  };

  const handleDeleteSetting = async () => {
    try {
      const response = await axios.delete(`${baseURL}/discountSettings/${settingToDelete.id}`);
      console.log("Delete response:", response.data); // Add log here
      if (response.data.status === 1) {
        fetchDiscountSettings();
        setShowDeleteModal(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting discount setting:", error);
      alert("Error deleting discount setting: " + error.message);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingSetting((prev) => ({ ...prev, [name]: value }));
  };

  const handleShowDeleteModal = (setting) => {
    setSettingToDelete(setting);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSettingToDelete(null);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedDiscountSettings = React.useMemo(() => {
    let sortableItems = [...discountSettings];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [discountSettings, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <Container fluid>
      <Row>
        <Col md={4}>
          <Form onSubmit={editingSetting ? handleUpdateSetting : handleAddSetting}>
            <Form.Group controlId="formUsername">
              <Form.Label>帳號</Form.Label>
              <Form.Control
                as="select"
                name="username"
                value={editingSetting ? editingSetting.username : newSetting.username}
                onChange={editingSetting ? handleEditInputChange : handleInputChange}
                disabled={!!editingSetting}
              >
                <option value="">選擇使用者</option>
                {users.map((user) => (
                  <option key={user.username} value={user.username}>
                    {user.username}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formDiscountName">
              <Form.Label>折扣名目</Form.Label>
              <Form.Control
                type="text"
                name="discount_name"
                value={editingSetting ? editingSetting.discount_name : newSetting.discount_name}
                onChange={editingSetting ? handleEditInputChange : handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDiscountRate">
              <Form.Label>折扣折數 (%)</Form.Label>
              <Form.Control
                type="number"
                name="discount_rate"
                min="1"
                max="100"
                value={editingSetting ? editingSetting.discount_rate : newSetting.discount_rate}
                onChange={editingSetting ? handleEditInputChange : handleInputChange}
                disabled={!!newSetting.discount_amount || !!editingSetting?.discount_amount}
              />
            </Form.Group>
            <Form.Group controlId="formDiscountAmount">
              <Form.Label>折扣金額</Form.Label>
              <Form.Control
                type="number"
                name="discount_amount"
                min="1"
                max="10000"
                value={editingSetting ? editingSetting.discount_amount : newSetting.discount_amount}
                onChange={editingSetting ? handleEditInputChange : handleInputChange}
                disabled={!!newSetting.discount_rate || !!editingSetting?.discount_rate}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editingSetting ? "更新" : "新增"}
            </Button>
            {editingSetting && (
              <Button
                variant="secondary"
                onClick={() => setEditingSetting(null)}
                className="mt-3 ml-3"
              >
                取消
              </Button>
            )}
          </Form>
        </Col>
        <Col md={6}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th onClick={() => handleSort('username')}>帳號 {getSortIcon('username')}</th>
                <th onClick={() => handleSort('discount_name')}>折扣名目 {getSortIcon('discount_name')}</th>
                <th onClick={() => handleSort('discount_rate')}>折扣折數 (%) {getSortIcon('discount_rate')}</th>
                <th onClick={() => handleSort('discount_amount')}>折扣金額 {getSortIcon('discount_amount')}</th>
                <th>動作</th>
              </tr>
            </thead>
            <tbody>
              {sortedDiscountSettings.map((setting) => (
                <tr key={setting.id}>
                  <td>{setting.username}</td>
                  <td>{setting.discount_name}</td>
                  <td>{setting.discount_rate || "-"}</td>
                  <td>{setting.discount_amount || "-"}</td>
                  <td>
                    <i className='bx bxs-edit-alt me-2' style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => handleEditSetting(setting)}></i>
                    <i className='bx bx-trash' style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => handleShowDeleteModal(setting)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>確認刪除</Modal.Title>
        </Modal.Header>
        <Modal.Body>您確定要刪除這個折扣設定嗎？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            取消
          </Button>
          <Button variant="danger" onClick={handleDeleteSetting}>
            確認
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DiscountSettings;
