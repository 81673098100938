import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from './manager/apiConfig'; // 引用 baseURL
import { Container, Form, Button, Modal, Col, FloatingLabel, Row } from 'react-bootstrap';

function UserProfile() {
  const [user, setUser] = useState({
    username: localStorage.getItem('username'), // 从 localStorage 获取 username
  });

  const [recipients, setRecipients] = useState([]);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    console.log('Fetching user data for:', user.username); // 添加日志
    axios.get(`${baseURL}/userProfile/getProfile`, { params: { username: user.username } })
      .then(response => {
        console.log('Response received:', response); // 添加日志
        if (response.data && response.data.length > 0) {
          console.log('User data:', response.data); // 添加日志
          setUser(prevState => ({
            ...prevState, // 保留原有的 user state
            username: response.data[0].username, // 假設每個收件人記錄中包含相同的用戶名
          }));
          setRecipients(response.data); // 將所有收件人資料設置到 recipients 狀態
        } else {
          console.log('No valid user data received:', response.data); // 添加日志
        }
      })
      .catch(error => console.error('Error fetching user data:', error));
  }, [user.username]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const newRecipients = [...recipients];
    newRecipients[index][name] = value;
    setRecipients(newRecipients);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(`${baseURL}/userProfile/update`, recipients)
      .then(response => {
        if (response.data.status === 1) {
          setModalMessage('更新完畢');
          setShowModal(true);
        } else {
          setModalMessage('Error updating profile');
          console.error('Error updating profile:', response.data);
          setShowModal(true);
        }
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setModalMessage('Error updating profile');
        setShowModal(true);
      });
  };

  const handleCloseModal = () => setShowModal(false);

  const addRecipient = () => {
    setRecipients([...recipients, {
      id: null,
      recipient_name: '',
      email: '',
      mobile: '',
      address_line1: '',
      address_line2: '',
      postal_code: '',
      notes: ''
    }]);
  };

  const renderFormGroup = (label, name, value, type = "text", required = false, index, maxLength = 250) => (
    <Form.Group as={Col} controlId={`formGrid${name}${index}`} key={name}>
      <FloatingLabel
        label={
          <span>
            {label}
            {required && <span style={{ color: 'red' }}> *</span>}
          </span>
        }
      >
        <Form.Control
          type={type}
          name={name}
          value={value}
          onChange={(e) => handleInputChange(e, index)}
          placeholder={`請輸入${label}`}
          required={required}
          maxLength={maxLength}
          isInvalid={!!errors[name]}
          disabled={name === 'username'}
        />
        <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );

  return (
    <>
      <header className="bg-success py-5 mb-5">
        <div className="container px-4 px-lg-5 my-5">
          <div className="text-center text-white">
            <h1 className="display-4 fw-bolder" style={{ color: 'white' }}>個人資訊</h1>
            <div className="d-flex justify-content-center">
              <div className="p-2 mb-2 bg-light text-dark me-2">收貨資訊</div>
              <div className="p-2 mb-2 bg-transparent text-white-50"
                style={{ cursor: 'pointer' }}
                onClick={() => window.location.href = `/manager/ListProducts`}>我的訂單</div>
            </div>
          </div>
        </div>
      </header>
      <Container className="mt-5">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            {renderFormGroup('登入帳號', 'username', user.username, 'text', true, 0, 50)}
          </Row>
          {recipients.map((recipient, index) => (
            <React.Fragment key={index}>
              <Row className="mb-3">
                {renderFormGroup('收件人姓名', 'recipient_name', recipient.recipient_name, 'text', true, index, 50)}
                {renderFormGroup('Email', 'email', recipient.email, 'email', true, index, 250)}
                {renderFormGroup('手機', 'mobile', recipient.mobile, 'text', true, index, 20)}
              </Row>
              <Row className="mb-3">
                {renderFormGroup('郵遞區號', 'postal_code', recipient.postal_code, 'text', true, index, 5)}
                {renderFormGroup('送貨地址', 'address_line1', recipient.address_line1, 'text', true, index, 250)}
                {/* {renderFormGroup('送貨地址2', 'address_line2', recipient.address_line2, 'text', false, index, 250)} */}
                
              </Row>
              <Row className="mb-3">
                {renderFormGroup('備註', 'notes', recipient.notes, 'text', false, index, 250)}
              </Row>
              <hr />
            </React.Fragment>
          ))}
          <Button variant="primary" type="submit" className="mt-3 ms-3">
            更新資料
          </Button>
        </Form>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>訊息</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default UserProfile;
