import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from './apiConfig';
import '../css/ModifyProduct.css';
import { Modal, Button, Form, Carousel, Nav } from 'react-bootstrap';
import { useProductManagement } from './ProductManagementContext';
import ProductForm from './ProductForm';
import QuantityBtn from '../QuantityBtn';
import YouTube from 'react-youtube';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ModifyProduct = () => {
  const { selectedProductId } = useProductManagement();
  const id = selectedProductId;

  const initialProductState = {
    產品特色詳細: {
      text: '',
      youtubeUrl: ''
    },
    產品規格: '',
    左眼度數: '',
    右眼度數: '',
    分類: []  // 新增的初始化分類
  };

  const [product, setProduct] = useState(initialProductState);
  const [productImages, setProductImages] = useState([]);
  const [featureImages, setFeatureImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [featureImagePreviews, setFeatureImagePreviews] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [imageError, setImageError] = useState('');
  const [originalProductImages, setOriginalProductImages] = useState([]);
  const [originalFeatureImages, setOriginalFeatureImages] = useState([]);
  const [mainImageChanged, setMainImageChanged] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [editingField, setEditingField] = useState(null);
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    fetchProductData();
  }, [id]);

  useEffect(() => {
    let interval;
    if (editingField) {
      interval = setInterval(() => {
        setFlash((prev) => !prev);
      }, 500);
    } else {
      clearInterval(interval);
      setFlash(false);
    }
    return () => clearInterval(interval);
  }, [editingField]);

  const cleanCategories = (categoriesString) => {
    return categoriesString.split(',').filter(Boolean); // 分割並過濾掉空值
  };

  const fetchProductData = async () => {
    try {
      const response = await axios.get(`${baseURL}/productCRUD/${id}`);
      const productData = response.data;
  
      if (!productData || productData.length === 0) {
        throw new Error("No product data found");
      }
  
      const initialProductData = {
        ...productData[0],
        產品特色詳細: {
          text: productData[0].product_features_text || '', // 確保 text 有值
          youtubeUrl: productData[0].product_features_youtubeUrl || '' // 確保 youtubeUrl 有值
        },
        產品規格: productData[0].產品規格 || '', // 確保 產品規格 有值
        左眼度數: productData[0].左眼度數 || '', // 確保 左眼度數 有值
        右眼度數: productData[0].右眼度數 || '', // 確保 右眼度數 有值
        分類: productData[0].分類 ? productData[0].分類.split(',').filter(Boolean) : [] // 確保 分類 是有效的數組，過濾掉空值
      };
  
      const storedProductImages = [];
      const storedFeatureImages = [];
      const productImagePreviews = [];
      const featureImagePreviews = [];
  
      productData.forEach(item => {
        if (item.product_features_text && !initialProductData.產品特色詳細.text) {
          initialProductData.產品特色詳細.text = item.product_features_text;
        }
        if (item.product_features_youtubeUrl && !initialProductData.產品特色詳細.youtubeUrl) {
          initialProductData.產品特色詳細.youtubeUrl = item.product_features_youtubeUrl;
        }
        if (item.product_features_img) {
          storedFeatureImages.push({
            id: item.id,
            path: item.product_features_img,
            isNew: false
          });
          featureImagePreviews.push(item.product_features_img);
        }
        if (item.product_img) {
          storedProductImages.push({
            id: item.id,
            path: item.product_img,
            isMain: item.product_img_main === "1",
            isNew: false
          });
          productImagePreviews.push(item.product_img);
        }
      });
  
      setProduct(initialProductData);
      setProductImages(storedProductImages);
      setFeatureImages(storedFeatureImages);
      setImagePreviews(productImagePreviews.map(path => process.env.PUBLIC_URL + '/img/' + path));
      setFeatureImagePreviews(featureImagePreviews.map(path => process.env.PUBLIC_URL + '/img/' + path));
  
      setOriginalProductImages(storedProductImages);
      setOriginalFeatureImages(storedFeatureImages);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };
  

  const onDrop = (acceptedFiles, callback) => {
    const compressImage = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const maxWidth = 1200;
            const maxHeight = 1200;

            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              resolve(new File([blob], file.name, { type: file.type }));
            }, file.type, 0.7);
          };
        };
      });
    };

    const compressAllImages = async () => {
      const compressedImages = await Promise.all(
        acceptedFiles.map((file) => compressImage(file))
      );
      callback(compressedImages);
    };

    compressAllImages();
  };

  const handleProductImagesDrop = (acceptedFiles) => {
    onDrop(acceptedFiles, (compressedImages) => {
      const newProductImages = compressedImages.map((file) => ({
        file,
        isNew: true,
        isMain: false,
      }));
      setProductImages((prevImages) => [...prevImages, ...newProductImages]);

      const previewUrls = compressedImages.map((file) => URL.createObjectURL(file));
      setImagePreviews((prevPreviews) => [...prevPreviews, ...previewUrls]);
      setImageError('');
    });
  };

  const handleFeatureImagesDrop = (acceptedFiles) => {
    onDrop(acceptedFiles, (compressedImages) => {
      const newFeatureImages = compressedImages.map((file) => ({
        file,
        isNew: true,
      }));
      setFeatureImages((prevImages) => [...prevImages, ...newFeatureImages]);

      const previewUrls = compressedImages.map((file) => URL.createObjectURL(file));
      setFeatureImagePreviews((prevPreviews) => [...prevPreviews, ...previewUrls]);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: value
    }));
    setEditingField(name);
  };

  const handleSpecChange = (value) => {
    setProduct(prevProduct => ({
      ...prevProduct,
      產品規格: value
    }));
  };

  const handleRemoveProductImage = (index) => {
    const updatedProductImages = productImages.filter((_, i) => i !== index);
    const updatedImagePreviews = imagePreviews.filter((_, i) => i !== index);

    if (productImages[index].isMain && updatedProductImages.length > 0) {
      updatedProductImages[0].isMain = true;
      setMainImageChanged(true);
    }

    setProductImages(updatedProductImages);
    setImagePreviews(updatedImagePreviews);
  };

  const handleRemoveFeatureImage = (index) => {
    handleRemoveImage(index, setFeatureImages, setFeatureImagePreviews, featureImages, featureImagePreviews);
  };

  const handleRemoveImage = (indexToRemove, setImageFunc, setPreviewFunc, images, previews) => {
    setImageFunc(images.filter((_, index) => index !== indexToRemove));
    setPreviewFunc(previews.filter((_, index) => index !== indexToRemove));
  };

  const handleSetMainProductImage = (index) => {
    handleSetMainImage(index, setProductImages, productImages);
    setMainImageChanged(true);
  };

  const handleSetMainImage = (index, setImageFunc, images) => {
    const updatedImages = images.map((image, i) => ({
      ...image,
      isMain: i === index
    }));
    setImageFunc(updatedImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (productImages.length === 0) {
      setImageError('請選擇至少一張產品圖');
      setModalMessage('請選擇至少一張產品圖');
      setModalShow(true);
      return;
    }

    const formData = new FormData();
    formData.append('product', JSON.stringify(product));
    formData.append('mainImageChanged', mainImageChanged);
    formData.append('mainImageFileName', productImages.find(image => image.isMain)?.path);

    productImages.forEach((image, index) => {
      if (image.file) {
        formData.append(`productImages[${index}]`, image.file);
      } else {
        formData.append(`productImages[${index}]`, image.path);
      }
      formData.append(`productImages[${index}][isNew]`, image.isNew);
      formData.append(`productImages[${index}][isMain]`, image.isMain);
    });

    featureImages.forEach((image, index) => {
      if (image.file) {
        formData.append(`featureImages[${index}]`, image.file);
      } else {
        formData.append(`featureImages[${index}]`, image.path);
      }
      formData.append(`featureImages[${index}][isNew]`, image.isNew);
    });

    const deletedProductImages = originalProductImages.filter(
      original => !productImages.some(current => current.path === original.path)
    );
    const deletedFeatureImages = originalFeatureImages.filter(
      original => !featureImages.some(current => current.path === original.path)
    );

    formData.append('deletedProductImages', JSON.stringify(deletedProductImages));
    formData.append('deletedFeatureImages', JSON.stringify(deletedFeatureImages));

    try {
      const response = await axios.post(`${baseURL}/productCRUD/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.status === 1) {
        setModalMessage("更新成功");
        setProduct(initialProductState);
        setProductImages([]);
        setFeatureImages([]);
        setImagePreviews([]);
        setFeatureImagePreviews([]);
      } else if (response.data.status === 0) {
        setModalMessage("失敗" + JSON.stringify(response.data));
      } else {
        setModalMessage("其他" + JSON.stringify(response.data));
      }
      setModalShow(true);
    } catch (error) {
      console.error('Error:', error);
      setModalMessage('新增失敗');
      setModalShow(true);
    }
  };

  const getYouTubeVideoId = (url) => {
    const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    if (match && match[1]) {
      return match[1];
    } else {
      return '';
    }
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div>
      <div className="container d-flex">
        <div className="w-50">
          <SimpleReactLightbox>
            <div style={{ position: "relative", top: "0px" }}>
              <div className="block-inner">
                <div className="ProductDetail-product row">
                  <div className="ProductDetail-product-gallery col-xs-12 col-md-12">
                    <div className="variant-gallery-stage text-center product-image-container">
                      <SRLWrapper>
                        <Carousel activeIndex={selectedIndex} onSelect={(selectedIndex) => setSelectedIndex(selectedIndex)}>
                          {productImages && productImages.map((product, index) => (
                            product.path && (
                              <Carousel.Item key={index}>
                                <a href={process.env.PUBLIC_URL + '/img/' + product.path}>
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/' + product.path}
                                    alt={`Product Image ${index + 1}`}
                                    width="450"
                                  />
                                </a>
                              </Carousel.Item>
                            )
                          ))}
                        </Carousel>
                      </SRLWrapper>
                    </div>
                    <div className="thumbnails-container">
                      {productImages && productImages.map((product, index) => (
                        product.path && (
                          <img
                            key={index}
                            src={process.env.PUBLIC_URL + '/img/' + product.path}
                            alt={`Product Thumbnail ${index + 1}`}
                            className="thumbnail"
                            onClick={() => setSelectedIndex(index)}
                          />
                        )
                      ))}
                    </div>
                  </div>

                  {product && (
                    <div className={`ProductDetail-product-info col-xs-12 col-md-12 ${flash && editingField ? 'editing' : ''}`}>
                      <div className="box-default">
                        <h3 className="Product-title mb-3" style={{ textAlign: "left" }}>{product.廠牌}-{product.商品名稱}</h3>
                        {product.注意事項 && (<p className="mb-3" style={{ borderBottom: "1px solid black", paddingBottom: "10px" }}>※{product.注意事項}</p>)}
                        <div className="price-box bg-light mb-4">
                          {product.福利價 !== "0" ? (
                            <div>
                              <span className="discountPrice" style={{ fontWeight: 'bold', color: '#e5721d' }}>NT${product.福利價}</span>
                            </div>
                          ) : (
                            <span className="discountPrice">NT${product.售價}</span>
                          )}
                        </div>
                        <p style={{ whiteSpace: 'pre-wrap' }}>●{product.包片數}</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>●弧度: {product.弧度}</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>●{product.產品特色}</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{product.產品詳述}</p>
                        <QuantityBtn productInfo={product} sourcePage="ProductDetail" showControls={false}/>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Nav variant="tabs" defaultActiveKey="/home" className="d-flex justify-content-center">
                <Nav.Item>
                  <Nav.Link
                    onClick={() => handleTabClick(1)}
                    className={`Tab-label js-tab-label ${activeTab === 1 ? 'active' : ''}`}>
                    商品特色
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => handleTabClick(2)}
                    className={`Tab-label js-tab-label ${activeTab === 2 ? 'active' : ''}`}>
                    商品規格
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => handleTabClick(3)}
                    className={`Tab-label js-tab-label ${activeTab === 3 ? 'active' : ''}`}>
                    送貨及付款方式
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="ProductDetail-additionalInfo row" style={{ marginTop: '20px' }}>
                <div className="col-xs-12">
                  <section className="Tabset js-tabset" id="UAOFVFNW">
                    <div className="Tab-content js-tab-content" id="tabset-tab-UAOFVFNW-1" scroll-to="" scroll-offset="48">
                      {activeTab === 1 && (
                        <div className="ProductDetail-description">
                          <div className="ProductDetail-title primary-border-color-after">
                            商品特色
                          </div>
                          <div className="product-features">
                            <p style={{ whiteSpace: 'pre-wrap' }}>{product.產品特色詳細.text}</p>
                            {product.產品特色詳細.youtubeUrl && (
                              <div className="youtube-container">
                                <YouTube videoId={getYouTubeVideoId(product.產品特色詳細.youtubeUrl)} />
                              </div>
                            )}
                          </div>
                          {productImages && productImages.map((product, index) => (
                            <React.Fragment key={index}>
                              {product.product_features_youtubeUrl && (
                                <div className="mt-3 mb-3">
                                  <YouTube videoId={getYouTubeVideoId(product.product_features_youtubeUrl)} />
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                          {productImages && productImages.map((product, index) => (
                            <React.Fragment key={index}>
                              {product.product_features_text && (
                                <p>{product.product_features_text}</p>
                              )}
                            </React.Fragment>
                          ))}
                          {productImages && productImages.map((product, index) => (
                            <React.Fragment key={index}>
                              {product.product_features_img && (
                                <img
                                  src={process.env.PUBLIC_URL + '/img/' + product.product_features_img}
                                  alt={`Product Feature ${index + 1}`}
                                  className="product-feature-img"
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div className="ProductDetail-description">
                          <div className="ProductDetail-title primary-border-color-after">
                            商品規格
                          </div>
                          <ReactQuill 
                                value={product.產品規格} 
                                onChange={handleSpecChange} 
                                modules={{
                                    toolbar: [
                                        [{ 'font': [] }, { 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{ 'color': [] }, { 'background': [] }],
                                        [{ 'script': 'sub' }, { 'script': 'super' }],
                                        [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['direction', { 'align': [] }],
                                        ['link', 'image', 'video'],
                                        ['clean']
                                    ],
                                    clipboard: {
                                        matchVisual: false
                                    }
                                }}
                                style={{ minHeight: '200px' }}
                            />
                        </div>
                      )}
                      {activeTab === 3 && (
                        <div className="ProductDetail-description">
                          <div className="ProductDetail-title primary-border-color-after">
                            送貨及付款方式
                          </div>
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </SimpleReactLightbox>
        </div>

        <div className="w-50">
          <ProductForm
            product={product}
            errors={errors}
            setErrors={setErrors}
            setProduct={setProduct} 
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            imageError={imageError}
            productImages={productImages}
            imagePreviews={imagePreviews}
            handleRemoveProductImage={handleRemoveProductImage}
            handleSetMainProductImage={handleSetMainProductImage}
            handleProductImagesDrop={handleProductImagesDrop}
            featureImages={featureImages}
            featureImagePreviews={featureImagePreviews}
            handleRemoveFeatureImage={handleRemoveFeatureImage}
            handleFeatureImagesDrop={handleFeatureImagesDrop}
          />

          <Modal show={modalShow} onHide={() => {
            setModalShow(false);
            if (modalMessage.includes("成功")) {
              fetchProductData();
            }
          }}>
            <Modal.Header closeButton>
              <Modal.Title>產品管理</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => {
                setModalShow(false);
                if (modalMessage.includes("成功")) {
                  fetchProductData();
                }
              }}>
                關閉
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ModifyProduct;