import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // 將 isLoggedIn 設為 false
    localStorage.setItem('isLoggedIn', 'false');
    // 重新導向到首頁
    navigate('/'); // 重新導向到首頁，假設首頁路徑為 '/'

    window.location.reload();

  }, [navigate]);

  return null; // 因為不需要渲染任何內容，因此返回 null
}

export default Logout;
